import request from '@/utils/request'

/**
 * 海报图
 * @param query
 * @returns {*}
 */
// 海报图
export function getPoster(query) {
    return request({
        url: '/user/user/poster/list',
        method: 'get',
        params: query
    })
}