import request from '@/utils/request'

/**
 * 轮播图
 * @param query
 * @returns {*}
 */
// 查询参数列表
export function getsendSms(phoneNum) {
    return request({
        url: '/user/login/sendSms?phoneNum=' + phoneNum,
        method: 'get',
    })
}

/**
 * 保存提现人信息
 * @param query
 * @returns {*}
 */
// 保存提现人信息
export function addWithdrawOrder(data) {
    return request({
        url: '/user/withdraworder/addWithdrawOrder',
        method: 'post',
        data:data
    })
}
