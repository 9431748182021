<script>
import { teamAgent } from '@/api/teamAgent'
export default {
  data() {
    return {
      title: "客户管理",
      isBack: true,
      teamAgentlist:[]
    };
  },
  created() {
    this.teamAgent()
  },
  mounted() {
    document.title = this.title;
  },
  methods: {
    teamAgent(){
      teamAgent().then((res)=>{
        this.teamAgentlist = res.data
      })
    }
  },
};
</script>

<template>
  <div class="bg">
    <div class="item">
      <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->
      <div class="bak">
        <div class="item2">
          <div class="item-left">
            <p>{{ teamAgentlist.teamTotal }}</p>
            <p>团队总数（人）</p>
          </div>
          <div class="item-right">
            <p>{{ teamAgentlist.teamAdd }}</p>
            <p>本月团队新增（人）</p>
          </div>
        </div>
        <div class="item2">
          <div class="item-left">
            <p>{{ teamAgentlist.teamTotalPerformance }}</p>
            <p>总业绩（元）</p>
          </div>
          <div class="item-right">
            <p>{{ teamAgentlist.teamMonthPerformance }}</p>
            <p>本月总业绩（元）</p>
          </div>
        </div>
      </div>
      <p class="text2">已加载全部</p>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../config/less/global.less";
.bg {
  width: 100%;
  height: 100vh;
  background: @base-bgColor;
}

p {
  margin: 0;
}

.bak {
  background: url("../../../assets/img/background/bluebak.png") no-repeat;
  border-radius: 15px;
  background-size: cover;
  background-color: #fff;
  width: 95%;
  height: 170px;
  margin: 5% 3%;
}
.item2 {
  position: relative;
  flex-direction: row;
  display: flex;
}

.item-left,.item-right{
  flex-direction: column;
  display: flex;
  width: 48%;
  margin: 5% 5%;
  color: #fff;
}

.text2 {
  margin-top: 25%;
  text-align: center;
  color: #939393;
  position: absolute;
  top: 25%;
  left: 40%;
}
</style>
