<script>
import GoodsItem from "../../components/goodItem.vue";
import {getBannerList} from "@/api/banner";
import {getLkl} from "@/api/shenKa";
import {getUserInfo} from "@/api/login";

export default {
  components: {GoodsItem},
  data() {
    return {
      title: "申卡",
      isBack: true,
      ifchkbox: false,
      bannerList: [1, 2],
      statusFromQuery: null,
      EwmData: {
        loginType: "",
        agentCode: "",
        bankId: "",
      },
      dataList: {
        bankBrandId: "",
        nameOfCardholder: "",
        phone: "",
        identityCard: "",
        agentCode: "",
      },
    };
  },
  created() {
    // 查询轮播图列表
    this.getBannerList();
    //判断信息
    this.setMessage();
  },
  mounted() {
    document.title = this.title;
    this.EwmData.loginType = this.$route.query.loginType;
  },
  methods: {
    //判定是否为未注册直接跳转
    iftk() {
      if (this.EwmData.loginType) {
        this.dataList.agentCode = this.$route.query.agentCode;
        this.dataList.bankBrandId = this.$route.query.bankId;
      }
    },

    // 查询轮播图列表
    getBannerList() {
      getBannerList().then((res) => {
        this.bannerList = res.data;
      });
    },
    setMessage() {
      this.dataList.bankBrandId = this.$route.query.id;
      this.statusFromQuery = this.$route.query.status;
      this.getUserInfo();
    },
    getLkl(passedDataList) {
      if (this.ifchkbox) {
        getLkl(passedDataList).then((res) => {
          console.log(res.data);
          alert('申卡成功')
          this.$router.push({
            name: "Module1",
          })
        });
      }
    },
    getUserInfo() {
      getUserInfo().then((res) => {
        console.log(res.data);
        if (this.statusFromQuery === 1) {
          (this.dataList.nameOfCardholder = res.data.agentName),
              (this.dataList.phone = res.data.agentMobile),
              (this.dataList.identityCard = res.data.identityCard);
          this.dataList.agentCode = res.data.agentCode;
        } else if (this.statusFromQuery === 0) {
          this.dataList.agentCode = res.data.agentCode;
        }
      });
    },
    goTourl(url) {
      window.location.href = `${url}`; // 注意添加http://或https://前缀
    },
  },
};
</script>

<template>
  <div class="bg">
    <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->

    <!-- 轮播图 接入后端 -->
    <div class="lunbo">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in bannerList" :key="item.id">
          <!-- <img :src="item.url" alt="图"/>  -->
          <img src="../../assets/img/background/bak1.png" alt="图"/>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="item">
      <h2>填写信息，继续申卡</h2>
      <div class="line"></div>
      <p class="text2">
        本业务免费办理，请勿相信任何收费，押金行为，请如实填写信息。
      </p>
      <div class="promess">
        <el-steps
            :active="statusFromQuery"
            finish-status="success"
            :align-center="true"
        >
          <el-step title="填写信息"></el-step>
          <el-step title="完善资料"></el-step>
          <el-step title="等待审核"></el-step>
        </el-steps>
      </div>

      <div class="form">
        <div>
          <label for="realName"><span>*</span>真实姓名：</label>
          <input
              type="text"
              id="realName"
              v-model="dataList.nameOfCardholder"
              placeholder="请输入真实姓名"
          />
        </div>
        <div>
          <label for="phone"><span>*</span>联系方式：</label>
          <input
              type="text"
              id="phone"
              v-model="dataList.phone"
              maxlength="11"
              minlength="11"
              placeholder="请输入手机号码"
          />
        </div>
        <div>
          <label for="idCard"><span>*</span>身份证号：</label>
          <input
              type="text"
              id="idCard"
              v-model="dataList.identityCard"
              maxlength="18"
              placeholder="请输入身份证号"
          />
        </div>
        <div class="checkbox">
          <input type="checkbox" v-model="ifchkbox" id="serviceAgreement"/>
          <label for="serviceAgreement" style="width: 100%; font-size: 12px"
          >我同意《服务协议》、《平台用户注册协议》、《共享经济合作伙伴协议》、《隐私政策》</label
          >
        </div>
        <div class="btn">
          <button @click="getLkl(dataList)">确认实名，下一步开始申卡</button>
        </div>
      </div>

      <div class="bottomtxt">
        <h2>温馨提示：</h2>
        <p v-for="(item, index) in bannerList" :key="index">
          {{ index + 1 }}
          、必须真实填写个人信息，且必须跟申卡信息保持一致，不得包装使用虚假信息，否则无法在平台办理任何业务；
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
// 看 vue.config.js
@import "../../config/less/global.less";

.bg {
  width: 100%;
  //   height: 200vh;
  padding-bottom: 200px;
  background: #f6f6f6;
  background: #f6f6f6;
}

.item {
  margin: 10px auto;
  width: 90%;
  border-radius: 10px;
  background-color: #ffffff;
}

.bottomtxt {
  margin: 10px;
  padding-bottom: 80px;
}

.btn button {
  border: none;
  padding: 3% 21%;
  color: #f6f6f6;
  font-weight: 600;
  border-radius: 20px;
  background-color: #fc2b3b;
  box-shadow: 10px 10px 10px -5px #fff3bf;
  font-size: 14px;
}

.form {
  z-index: 1000;
}

.form div {
  margin: 20px;
  display: flex;
}

.form div:not(.btn, .checkbox) {
  border-bottom: 0.5px solid #d3d1d1;
}

.form input {
  background-color: #ffffff;
  border: none;
  z-index: 1004;
}

label {
  display: block;
  color: #666666;
  font-size: 14px;
  height: 30%;
  width: 80px;
  margin: 10px -7px 10px 0;
}

label span {
  color: red;
}

h2 {
  padding: 5% 0 0 5%;
}

.text2 {
  font-size: 10px;
  margin-left: 5%;
}

.promess {
  width: 95%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.line {
  height: 2px;
  width: 100%;
  background-color: #f6f6f6;
  margin: 0 auto;
}

.lunbo {
  // background-image: linear-gradient(0deg, #3bf13e, #38bc9d 50%);
  margin: 10px;
}

.my-swipe {
  margin: 0 auto 0;
  border-radius: 15px;
  width: 360px;
  height: 150px;
}

.my-swipe .van-swipe-item {
  color: #fff;
  padding: 0;
  text-align: center;
  background-color: #ffffff;
}

.my-swipe .van-swipe-item img {
  width: 100%;
  height: 100%;
}

.my-swipe1 .van-swipe-item {
  color: #fff;
  text-align: center;
  background-color: #ffffff;
}
</style>
