<script>
import { getMyagentList } from "@/api/mine";
import { wxLogin } from "@/api/login";
export default {
  data() {
    return {
      title: "个人中心",
      isBack: false,
      MyagentList: [],
    };
  },
  created() {
    // this.getMyagentList();

    this.login();
  },
  mounted() {
    document.title = this.title;
  },
  methods: {

    login() {
      const appid = "wxcdd57cd19c66f1ab"; //公众号的appid
      const callback_url = encodeURIComponent(window.location.href); // 获取当前地址，跳转至以下腾讯地址会携带code重定向到该地址
      console.log(callback_url);
      let data = this.GetRequest(); // 获取链接参数
      if (data.code === "" || data.code == null) {
        // console.log(data.code);
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${callback_url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      } else {
        if (!localStorage.getItem("Admin-Token")) {
          // 登录的逻辑
          wxLogin({ code: data.code })
            .then((res) => {
              localStorage.setItem(res.token);
              console.log(res.token);
              if (res.data.code == 1) {
                this.$router.replace({
                  path: "/SignIn",
                  query: { code: data.code },
                });
              } else if (res.token) {
                this.getMyagentList();
              }
            })
            .catch((err) => {
              // 跳转注册页面
              this.$router.replace({
                path: "/SignIn",
                query: { code: data.code },
              });
            });
        } else {
          this.getMyagentList();
        }
      }
    },

    GetRequest() {
      var url = decodeURI(location.href);
      var object = {};
      if (url.indexOf("?") != -1) {
        var index = url.indexOf("?");
        var str = url.substring(index + 1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          object[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return object;
    },
    showGood(item) {
      this.$router.push({
        name: "WithdrawalDetails",
      });
    },
    history() {
      console.log("点击更多按钮");
      this.$router.push({
        name: "History",
      });
    },
    events() {
      this.$router.push({
        name: "Events",
      });
    },
    monad() {
      this.$router.push({
        name: "Monad",
      });
    },
    team() {
      this.$router.push({
        name: "Team",
      });
    },
    Inviter() {
      this.$router.push({
        name: "Inviter",
      });
    },
    notification() {
      this.$router.push({
        name: "Notification",
      });
    },
    recommended() {
      this.$router.push({
        name: "Recommended",
      });
    },
    schedule() {
      this.$router.push({
        name: "Schedule",
      });
    },
    getMyagentList() {
      getMyagentList().then((res) => {
        console.log(res.data, "个人中心获取信息");
        this.MyagentList = res.data;
      });
    },
    copyToClipboard(text) {
      // 创建一个临时的textarea元素
      const textarea = document.createElement("textarea");

      // 防止页面布局被改变
      textarea.style.position = "fixed";
      textarea.style.top = 0;
      textarea.style.left = 0;
      textarea.style.opacity = 0;

      // 将要复制的文本设置为textarea的值
      textarea.value = text;

      // 将textarea添加到body中
      document.body.appendChild(textarea);

      // 选中textarea的文本
      textarea.focus();
      textarea.select();

      // 尝试执行复制命令
      const successful = document.execCommand("copy");

      // 清理textarea
      document.body.removeChild(textarea);

      // 弹出提示信息
      if (successful) {
        console.log("已复制到剪贴板");
        alert('已复制到剪贴板')
      } else {
        console.log("复制失败，请手动复制");
      }
    },
    exitlogin() {
      localStorage.removeItem("Admin-Token");
      localStorage.removeItem("hasLoggedIn");

      window.location.href = 'http://tktest.yiqichuangying.com/';
    },
  },
};
</script>

<template>
  <div class="bg">
    <div class="item">
      <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->

      <!-- 个人信息 -->
      <div class="details">
        <div class="details_top">
          <!-- 头像 左 -->
          <!-- <img src="../../assets/img/toux.png" alt="" /> -->
          <img :src="MyagentList.cover" alt="" />

          <!-- 人物信息 右 -->
          <div class="details_top_right">
            <!-- 姓名 -->
            <p class="details_title">{{ MyagentList.agentName }}</p>

            <!-- 电话+身份信息 -->
            <p class="phonedentity">
              <span class="phone">{{ MyagentList.agentMobile }}</span> 合伙人
            </p>

            <!-- 申卡码 -->
            <div class="shenka">
              <span>申卡码:{{ MyagentList.agentCode }}</span>
              <button class="copy" @click="copyToClipboard(MyagentList.agentCode)">
                复制
              </button>
            </div>
          </div>
        </div>
        <div class="details_bottom">
          <p>可提现收入</p>

          <p>
            ￥ <span>{{ MyagentList.amount }}</span>
          </p>

          <div class="money">
            <span>(总收入{{ MyagentList.amountSum }}元)</span>
            <div>
              <button class="money-btn1" @click="showGood()">提现</button>
              <button class="money-btn2" @click="showGood()">明细</button>
            </div>
          </div>
        </div>
      </div>

      <!-- 业绩展示 -->
      <div class="performance">
        <p class="performance-title">{{ MyagentList.currentMonth }}月总业绩：{{ MyagentList.currentMonthAmount }}元</p>
        <span class="gend" @click="history()">更多></span>

        <p class="totalperformance">
          <span>个人业绩：{{ MyagentList.personalAmount }}元</span>
          <span>团队业绩：{{ MyagentList.teamAmount }}元</span>
        </p>

        <div class="upgrades">
          距离升级<span>L{{ MyagentList.grade + 1 }}</span>还差{{ MyagentList.upgradeAmount }}元
        </div>

        <div class="prosess">
          <el-steps :active="MyagentList.grade" class="stepsStyle">
            <el-step title="L1"></el-step>
            <el-step title="L2"></el-step>
            <el-step title="L3"></el-step>
            <el-step title="L4"></el-step>
            <el-step title="L5"></el-step>
            <el-step title="L6"></el-step>
            <el-step title="L7"></el-step>
            <el-step title="L8"></el-step>
            <el-step title="L9"></el-step>
          </el-steps>
        </div>
      </div>

      <!-- 下方按钮 -->
      <div class="taskbtn">
        <div class="btnfor">
          <div class="btn" @click="team()">
            <img src="../../assets/img/icon1.png" />
            <p>团队管理</p>
          </div>
          <div class="btn" @click="monad()">
            <img src="../../assets/img/icon2.png" alt="" />
            <p>订单明细</p>
          </div>
          <div class="btn" @click="recommended()">
            <img src="../../assets/img/icon3.png" alt="" />
            <p>推荐办卡</p>
          </div>
          <div class="btn" @click="Inviter()">
            <img src="../../assets/img/icon4.png" alt="" />
            <p>邀请合伙人</p>
          </div>
          <div class="btn" @click="events()">
            <img src="../../assets/img/icon5.png" alt="" />
            <p>活动中心</p>
          </div>
          <div class="btn" @click="notification()">
            <img src="../../assets/img/icon6.png" alt="" />
            <p>系统通知</p>
          </div>
          <div class="btn" @click="schedule()">
            <img src="../../assets/img/icon7.png" alt="" />
            <p>结算进度</p>
          </div>
          <div class="btn" @click="exitlogin()">
            <img src="../../assets/img/exitlogin.png">
            <p>重新登录</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.bg {
  width: 100%;
  background: yellow;
  background: @base-bgColor;
  padding-bottom: 100px;
}

.item {
  box-sizing: border-box;
  padding: 3%;
  display: flex;
  flex-direction: column;
}

p {
  margin: 0;
}

.prosess {
  position: relative;
  width: 95%;
  top: 67%;
  left: 2%;
}

.stepsStyle {
  width: 100%;

  ::v-deep {

    .el-step__title.is-process,
    .el-step__title.is-wait {
      color: #c0c4cc !important;
      font-size: 14px;
      margin-top: -5px;
      margin-left: 8%;
    }

    .el-step__head.is-process,
    .el-step__heads.is-wait {
      .el-step__icon.is-text {
        //这里设置的是下一步和等待状态里的文字颜色
        color: #c0c4cc !important;
        border-color: #c0c4cc !important;
      }
    }

    .el-step__head.is-finish {
      color: orange !important;

      .el-step__icon.is-text {
        //这里设置的是已完成状态里的文字颜色
        color: orange !important;
        border-color: orange !important;
        font-size: 14px;
      }
    }

    .el-step__title.is-finish {
      color: orange !important;
      font-size: 14px;
      margin-left: 8%;
      margin-top: -5px;
    }

    .el-step__line-inner {
      //这里设置的是完成后过渡线条颜色
      border-color: orange !important;
    }

    .el-step__head.is-success,
    .el-step__title.is-success {
      //这里设置的是完成后的颜色
      color: orange !important;
      font-size: 14px;
      // margin-left: 4px;
    }
  }
}

.performance {
  position: relative;
  width: 100%;
  height: 180px;
  margin-top: 15px;
  // padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
}

.performance-title {
  position: absolute;
  font-weight: 600;
  font-size: 14px;
  top: 5%;
  left: 3%;
}

.gend {
  position: absolute;
  display: block;
  font-size: 14px;
  color: #969696;
  top: 6%;
  left: 85%;
  z-index: 22;
}

.gend:hover {
  color: red;
}

.totalperformance {
  position: absolute;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  top: 25%;
  left: 3%;
}

.totalperformance span {
  display: block;
  margin-right: 30px;
  box-sizing: border-box;
  font-size: 14px;
}

.upgrades {
  position: absolute;
  background-color: #ffffff;
  box-shadow: #969696 0px 0px 10px;
  padding: 3px 10px;
  border-radius: 5px;
  top: 45%;
  left: 28%;
  font-size: 14px;
}

.upgrades span {
  color: #f79f2b;
}

.taskbtn {
  position: relative;
  margin-top: 15px;
  width: 100%;
  // height: 190px;
  background-color: #ffffff;
  border-radius: 10px;
}

.btnfor {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 5px;
}

.btn {
  flex: 0 0 23%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

}

.btn:not(:nth-child(4n)) {
  margin-right: 2%;
}

.btn img {
  width: 60%;
}

.btn p {
  font-size: 14px;
  margin-top: 3px;
}

.details {
  position: relative;
  width: 100%;
  height: 250px;
  background-color: #fff;
  border-radius: 10px;
}

.details_top {
  position: relative;
  width: 320px;
  height: 130px;
  top: 5px;
  left: 13px;
  // background-color: black;
}

.details_top img {
  position: absolute;
  width: 27%;
  top: 20px;
  // left: 15px;
  border-radius: 90px;
}

.details_top_right {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 90px;
  // background-color: bisque;
  top: 20px;
  left: 100px;
  overflow: hidden;
}

.details_title {
  font-size: 14px;
  font-weight: 400;
}

.details_top_right p {
  margin: 0;
  line-height: 30px;
}

.phonedentity {
  font-size: 14px;
  display: flex;
  flex-direction: row;
}

.phonedentity span {
  display: block;
  width: 110px;
  color: silver;
  font-size: 14px;
  display: flex;
  flex-direction: row;
}

.shenka {
  // width: 200px;
  color: silver;
  font-size: 14px;
  display: flex;
  flex-direction: row;
}

.shenka span {
  display: block;
  width: 110px;
}

.copy {
  width: 41px;
  font-size: 10px;
  border: none;
  background: #fdeebf;
  color: #f97517;
  border-radius: 30px;
}

.details_bottom {
  margin: 0 auto;
  width: 90%;
  height: 120px;
  display: flex;
  flex-direction: column;
  // background-color: #babc8d;
}

.details_bottom p {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  line-height: 35px;
}

.details_bottom p span {
  display: block;
  font-size: 28px;
  font-weight: 600;
}

.money {
  margin: 0 auto;
  margin-top: 5px;
  width: 100%;
  // background-color: aqua;
  display: flex;
  justify-content: space-between;
}

.money span {
  display: block;
  color: #552f2f;
  font-size: 14px;
  line-height: 30px;
}

.money button {
  width: 60px;
  height: 30px;
  border-radius: 30px;
  font-weight: 600;
  margin-left: 10px;
  padding: 4px;
}

.money-btn1 {
  background-color: #f3c055;
  color: #fff;
  border: none;
  font-size: 14px;
}

.money-btn2 {
  background-color: #fff;
  border: #f3c055 solid 2px;
  color: #f3c055;
  font-size: 14px;
}
</style>
