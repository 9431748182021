import { render, staticRenderFns } from "./Shenka.vue?vue&type=template&id=7cfeeeba&scoped=true"
import script from "./Shenka.vue?vue&type=script&lang=js"
export * from "./Shenka.vue?vue&type=script&lang=js"
import style0 from "./Shenka.vue?vue&type=style&index=0&id=7cfeeeba&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cfeeeba",
  null
  
)

export default component.exports