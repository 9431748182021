import request from '@/utils/request'

/**
 * 我的
 * @param query
 * @returns {*}
 */
// 查询参数列表
export function getMyagentList(query) {
    return request({
        url: '/user/myagent/list',
        method: 'get',
        params: query
    })
}

export function getTeamAgentDetail(query){
    return request({
        url:'/user/myagent/teamAgentDetail',
        method: 'get',
        params: query
    })
}
