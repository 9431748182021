import request from '@/utils/request'

/**
 * 代理商注册
 * @param query
 * @returns {*}
 */
// 代理商注册
export function loginAgent(data) {
    return request({
        url: '/user/login/loginAgent',
        method: 'post',
        data: data
    })
}