import request from "@/utils/request";

/**
 * 订单列表
 * @param query
 * @returns {*}
 */
// 订单列表
export function orderList(query) {
  return request({
    url: "/user/order/list",
    method: "get",
    params: query
  });
}
