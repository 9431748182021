import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BaseNavBar from "./components/BaseNavBar.vue";
import BaseTopTabs from "./components/BaseTopTabs.vue";
import 'vant/lib/index.css';
import './config/vant/vant.js'
import './config/css/global.css'
import JhRefreshView from "./components/JhRefreshView/index.vue";
import BaseRefreshView from "./components/BaseRefreshView.vue";
import { Popup } from 'vant';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI,Popup);
Vue.component('BaseNavBar', BaseNavBar)
Vue.component('BaseTopTabs', BaseTopTabs)
Vue.component('JhRefreshView', JhRefreshView)
Vue.component('BaseRefreshView', BaseRefreshView)


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

