var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"itemlist"},[(
          _vm.MyagentList.amountSum === '' ||
          _vm.MyagentList.amountSum === null ||
          _vm.MyagentList.amountSum === undefined
        )?_c('p',{staticClass:"itemlist-p1"},[_vm._v(" 0 ")]):_c('p',{staticClass:"itemlist-p1"},[_vm._v(_vm._s(_vm.MyagentList.amountSum))]),_c('p',{staticClass:"itemlist-p2"},[_vm._v("收入总额（元）")])]),_c('div',{staticClass:"itemlist"},[(
          _vm.MyagentList.balance === '' ||
          _vm.MyagentList.balance === null ||
          _vm.MyagentList.balance === undefined
        )?_c('p',{staticClass:"itemlist-p1"},[_vm._v(" 0 ")]):_c('p',{staticClass:"itemlist-p1"},[_vm._v(_vm._s(_vm.MyagentList.balance))]),_c('p',{staticClass:"itemlist-p2"},[_vm._v("可结算（元）")])])]),_vm._m(0),_c('div',{staticClass:"tab"},[_c('div',{staticClass:"tabitem",class:{ active: _vm.activeTab === 'completed' },on:{"click":function($event){return _vm.setActiveTab('completed')}}},[_vm._v(" 已提成 ")]),_c('div',{staticClass:"tabitem",class:{ active: _vm.activeTab === 'uncompleted' },on:{"click":function($event){return _vm.setActiveTab('uncompleted')}}},[_vm._v(" 已结算 ")])]),(_vm.activeTab === 'completed')?_c('div',{staticClass:"tab-content"},_vm._l((_vm.orders),function(item){return (_vm.orders > 0)?_c('div',{key:item.id,staticClass:"tabindex"},[_c('span',{staticClass:"time"},[_vm._v("2024年06月20日"+_vm._s(item.time))]),_c('span',{staticClass:"money"},[_vm._v("—"+_vm._s(item.money)+"20")])]):(_vm.orders === 0)?_c('div',{staticClass:"notindex"},[_vm._v("暂无数据")]):_vm._e()}),0):_vm._e(),(_vm.activeTab === 'uncompleted')?_c('div',{staticClass:"tab-content"},_vm._l((_vm.records),function(item){return (_vm.records > 0)?_c('div',{key:item.id,staticClass:"tabindex"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(item.time))]),_c('span',{staticClass:"money"},[_vm._v("—"+_vm._s(item.money))])]):(_vm.records === 0)?_c('div',{staticClass:"notindex"},[_vm._v("暂无数据")]):_vm._e()}),0):_vm._e(),_c('div',{staticClass:"bottomChat"},[_c('div',{staticClass:"share-content"},[_c('div',{staticClass:"phoneBtn",on:{"click":function($event){return _vm.showGood()}}},[_vm._v("去提现")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inputK"},[_c('input',{attrs:{"type":"text","placeholder":"请输入"}}),_c('span',[_vm._v("搜索")])])
}]

export { render, staticRenderFns }