<script>
import { Card } from "element-ui";
import { getsendSms } from "@/api/bindingCard";
import { loginAgent } from "@/api/signIn";
import { setToken } from "@/utils/auth";
export default {
  data() {
    return {
      title: "合伙人注册",
      isBack: false,
      activeTab: "completed",
      isBankCardBound: false, // 银行卡是否绑定
      isServiceAgreementChecked: false,
      showAlert: false,
      alertMessage: "",
      isCounting: false,
      countdown: 60,
      cardId: "",
      dataList: {
        parentCode: "",
        userName: "",
        identityCard: "",
        wxCode: "",
        phone: "",
        dxCode: "",
        uuid: "",
        code: null,
      },
    };
  },
  created() {
    this.inputCode();
  },
  mounted() {
    const queryParams = new URLSearchParams(window.location.search);
    const agentCode = queryParams.get("agentCode");
    this.dataList.parentCode = agentCode;

    const cardIdTemp = queryParams.get("bankId");
    this.cardId = cardIdTemp;

    document.title = this.title;
  },

  methods: {
    inputCode() {
      const appid = "wx4cb24a6257a81b41"; //公众号的appid

      // 获取当前地址，跳转至以下腾讯地址会携带code重定向到该地址
      const callback_url = encodeURIComponent(window.location.href);
      console.log(callback_url);

      // 获取链接参数
      let data = this.GetRequest();
      console.log(data, "这是data 注册界面");

      if (data.code === "" || data.code == null) {
        console.log(data.code, "判断data.code是否存在 注册界面");
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${callback_url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      }
      this.dataList.code = data.code;
    },

    GetRequest() {
      //url例子：www.bicycle.com?id="123456"&Name="bicycle"；
      var url = decodeURI(location.href); //?id="123456"&Name="bicycle";
      var object = {};
      if (url.indexOf("?") != -1) {
        //url中存在问号，也就说有参数。
        var index = url.indexOf("?");
        var str = url.substring(index + 1); //得到?后面的字符串
        var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",Name="bicycle"];
        for (var i = 0; i < strs.length; i++) {
          object[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return object;
    },

    // && this.dataList.uuid
    //提交信息
    checkInput() {
      // this.dataList.parentCode &&
      if (
        this.dataList.userName &&
        this.dataList.identityCard.length === 18 &&
        this.dataList.phone.length === 11 &&
        this.dataList.wxCode &&
        this.dataList.dxCode &&
        this.isServiceAgreementChecked &&
        this.dataList.uuid
      ) {
        this.$toast.loading({
          duration: 0,
          message: "正在注册...",
        });
        console.log(this.dataList);
        this.loginAgent(this.dataList);
      } else {
        this.$toast.loading({
          duration: 0,
          message: "请信息补全再提交",
        });
        this.exit();
      }
    },

    //提示框
    hideAlert() {
      if (!this.isBankCardBound && this.alertMessage === "请先完善信息") {
        this.showAlert = false;
      } else {
        this.showAlert = false;
      }
    },

    //验证码
    async getVerificationCode() {
      if (this.isCounting) return; // 如果正在倒计时，则不执行

      // 这里可以添加发送验证码到手机号的逻辑
      if (this.dataList.phone) {
        // 假设验证码已成功发送，开始倒计时
        this.isCounting = true;
        this.countdown = 60;
        this.startCountdown();
        // 添加发送验证码的逻辑
        this.getsendSms(this.dataList.phone);
      } else {
        this.alertMessage = "手机号无效或长度不是11位";
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 3000);
      }
    },

    //开始验证码倒计时
    startCountdown() {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown--;
          this.startCountdown();
        }, 1000);
      } else {
        this.isCounting = false;
        this.countdown = 60;
      }
    },

    //发送验证码
    getsendSms(phoneNum) {
      // console.log(phoneNum);
      getsendSms(phoneNum).then((res) => {
        this.dataList.uuid = res.uuid;
      });
    },

    //等三秒关闭弹窗
    exit() {
      setTimeout(this.$toast.clear(), 3000);
    },

    //发送绑定信息表单 提交成功返回上层界面
    loginAgent(dataList) {
      loginAgent(dataList).then((res) => {
        if (res.token) {
          setToken(res.token);
          this.$router.replace({ path: "/Module1" });
          this.$toast.clear();
        } else {
          this.$toast.clear();
        }
      });
    },
  },
};
</script>

<template>
  <div class="bg">
    <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->

    <div class="item">
      <div class="line"></div>
      <p class="text2">个人信息将影响审核及收入提现，请确保信息真实有效</p>

      <div class="form">
        <div>
          <label for="realName">邀请码：</label>
          <input
            type="text"
            id="realName"
            v-model="dataList.parentCode"
            placeholder="请输入邀请码"
          />
        </div>
        <div>
          <label for="realName"><span>*</span>真实姓名：</label>
          <input
            type="text"
            id="realName"
            v-model="dataList.userName"
            placeholder="请输入真实姓名"
          />
        </div>
        <div>
          <label for="realName"><span>*</span>身份证号：</label>
          <input
            type="text"
            id="realName"
            v-model="dataList.identityCard"
            placeholder="请输入真实身份证号"
            maxlength="18"
          />
        </div>

        <div>
          <label for="realName"><span>*</span>微信号：</label>
          <input
            type="text"
            id="realName"
            v-model="dataList.wxCode"
            placeholder="请输入真实微信号"
          />
        </div>

        <div>
          <label for="phone"><span>*</span>手机号：</label>
          <input
            type="text"
            id="phone"
            v-model="dataList.phone"
            maxlength="11"
            placeholder="请输入真实手机号"
          />
        </div>

        <!-- 验证码 -->
        <div class="yzm">
          <label for="captcha"><span>*</span>验证码</label>
          <input
            type="text"
            name="captcha"
            placeholder="请输入验证码"
            v-model="dataList.dxCode"
            required
          />
          <button
            type="button"
            id="sendCaptcha"
            :disabled="isCounting"
            @click="getVerificationCode"
          >
            {{ isCounting ? "" + countdown + "s" : "获取验证码" }}
          </button>
        </div>
        <div class="checkbox">
          <input
            type="checkbox"
            id="serviceAgreement"
            v-model="isServiceAgreementChecked"
          />
          <label for="serviceAgreement" style="width: 100%; font-size: 12px"
            >我同意《注册协议》、《用户隐私保护政策》、《个人身份信息使用授权书》</label
          >
        </div>

        <div class="btn">
          <button @click="checkInput">提 交</button>
        </div>

        <!-- 提示弹窗 -->
        <transition name="fade">
          <div v-if="showAlert" class="alert-box">
            {{ alertMessage }}
            <button @click="hideAlert">确定</button>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
// 看 vue.config.js
@import "../../config/less/global.less";
.bg {
  width: 100%;
  //   height: 200vh;
  padding-bottom: 200px;
  background: #f6f6f6;
  background: #f6f6f6;
}

.item {
  margin: 0px auto;
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
}

.bottomtxt {
  margin: 10px;
}

.yzm input {
  width: 30%;
}

.yzm button {
  width: 27%;
  height: 30px;
  margin-left: 13%;
  background-color: #ffffff;
  border: #f3ba4f solid 2px;
  border-radius: 14px;
  font-size: 14px;
}

.btn button {
  width: 95%;
  border: none;
  padding: 3% 0;
  color: #f6f6f6;
  font-size: 20px;
  border-radius: 25px;
  background-color: #f3ba4f;
  margin-left: 2%;
  //   box-shadow: 10px 10px 10px -5px #fff3bf;
}

.form div {
  margin: 20px;
  display: flex;
}

.form div:not(.btn, .checkbox) {
  border-bottom: 0.5px solid #d3d1d1;
}

.form input {
  background-color: #ffffff;
  border: none;
  font-size: 14px;
}

input:not(#serviceAgreement, .yzm input) {
  width: 60%;
}

label {
  display: block;
  color: #666666;
  font-size: 14px;
  height: 30%;
  width: 24%;
  margin: 10px 0;
}

label span {
  color: red;
}

h2 {
  padding: 5% 0 0 5%;
}

.text2 {
  font-size: 14px;
  color: #7f7f7f;
  text-align: center;
}

.promess {
  width: 95%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.line {
  height: 2px;
  width: 100%;
  background-color: #f6f6f6;
  margin: 0 auto;
}

.lunbo {
  // background-image: linear-gradient(0deg, #3bf13e, #38bc9d 50%);
  margin: 10px;
}
.my-swipe {
  margin: 0 auto 0;
  border-radius: 15px;
  width: 360px;
  height: 150px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  padding: 0;
  text-align: center;
  background-color: #ffffff;
}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: 100%;
}
.my-swipe1 .van-swipe-item {
  color: #fff;
  text-align: center;
  background-color: #ffffff;
}
</style>
