<script>
import {
  getAccountLogSumList,
  getAgentAccountLog,
} from "@/api/withdrawalDetails";
export default {
  name: "WithdrawalDetails",
  data() {
    return {
      title: "收入明细",
      isBack: true,
      activeTab: "completed",
      MyagentList: [],
      tabdataList: [1, 2, 3, 4, 5, 6],
      orders: [1,2,3,4,5],
      records: [1,2,3,4,5],
    };
  },
  created() {
    this.getAccountLogSumList();
    this.getAgentAccountLog();
  },
  mounted() {
    document.title = this.title;
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    showGood() {
      this.$router.push({
        name: "Withdraw",
        query: {
          // id: item.id,
        },
      });
    },
    getAccountLogSumList() {
      getAccountLogSumList().then((res) => {
        this.MyagentList = res.data;
        console.log(res.data,"合计");
      });
    },
    getAgentAccountLog() {
      getAgentAccountLog().then((res) => {
        console.log(res.data,"列表");
        this.orders = res.data.orders;
        this.records = res.data.records;
      });
    },
  },
};
</script>

<template>
  <div class="bg">
    <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->

    <!-- 黑框 -->
    <div class="item">
      <div class="itemlist">
        <p
          class="itemlist-p1"
          v-if="
            MyagentList.amountSum === '' ||
            MyagentList.amountSum === null ||
            MyagentList.amountSum === undefined
          "
        >
          0
        </p>
        <p class="itemlist-p1" v-else>{{ MyagentList.amountSum }}</p>
        <p class="itemlist-p2">收入总额（元）</p>
      </div>
      <div class="itemlist">
        <p
          class="itemlist-p1"
          v-if="
            MyagentList.balance === '' ||
            MyagentList.balance === null ||
            MyagentList.balance === undefined
          "
        >
          0
        </p>
        <p class="itemlist-p1" v-else>{{ MyagentList.balance }}</p>
        <p class="itemlist-p2">可结算（元）</p>
      </div>
    </div>

    <!-- 输入框 -->
    <div class="inputK">
      <input type="text" placeholder="请输入" />
      <span>搜索</span>
    </div>

    <!-- tab框 -->
    <div class="tab">
      <div
        class="tabitem"
        :class="{ active: activeTab === 'completed' }"
        @click="setActiveTab('completed')"
      >
        已提成
      </div>
      <div
        class="tabitem"
        :class="{ active: activeTab === 'uncompleted' }"
        @click="setActiveTab('uncompleted')"
      >
        已结算
      </div>
    </div>

    <!-- 根据activeTab的值显示不同的div -->
    <div v-if="activeTab === 'completed'" class="tab-content">
      <div
        class="tabindex" v-for="item in orders" v-if="orders > 0" :key="item.id">
        <span class="time">2024年06月20日{{ item.time }}</span>
        <span class="money">—{{ item.money }}20</span>
      </div>
      <div class="notindex" v-else-if="orders === 0">暂无数据</div>
    </div>

    <div v-if="activeTab === 'uncompleted'" class="tab-content">
      <div class="tabindex" v-for="item in records" v-if="records > 0" :key="item.id">
        <span class="time">{{ item.time }}</span>
        <span class="money">—{{ item.money }}</span>
      </div>
      <div class="notindex" v-else-if="records === 0">暂无数据</div>
    </div>

    <!-- 底部俩按钮 -->
    <div class="bottomChat">
      <div class="share-content">
        <div class="phoneBtn" @click="showGood()">去提现</div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
// 看 vue.config.js
@import "../../../config/less/global.less";
.bg {
  width: 100%;
  background: yellow;
  background: @base-bgColor;
}
p {
  margin: 0;
}

.notindex {
  height: 40vh;
  font-size: 19px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.tabindex {
  margin: 20px 10px;
  display: flex;
  justify-content: space-between;
}

.tabindex .time {
  font-size: 20px;
}

.tabindex .money {
  color: #ffd866;
  font-size: 20px;
  font-weight: 600;
}

.bottomChat {
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.share-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.phoneBtn {
  width: 100%;
  height: 35px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  background-color: #ffd866;
}

.item {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
}
.itemlist {
  width: 49.5%;
  height: 100px;
  background-color: black;
  margin: 0px 0.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.itemlist-p1,
.itemlist-p2 {
  text-align: center;
  margin-bottom: 5px;
}

.itemlist-p1 {
  color: #ffd866;
  font-size: 20px;
  // top: 30%;
  // left: 36%;
  font-weight: 600;
}

.itemlist-p2 {
  color: #f8f8f8;
  font-size: 12px;
}

.inputK {
  width: 99%;
  height: 30px;
  margin-top: 1px;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: row;
}

.inputK input {
  border: none;
  width: 80%;
  margin: 0px 5%;
}
.inputK span {
  display: flex;
  line-height: 30px;
  color: #ffd866;
  font-size: 14px;
}

.tab {
  width: 99%;
  height: 30px;
  margin-top: 2px;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: row;
  border: 1px solid #eeeeee;
}

.tabitem {
  width: 49%;
  text-align: center;
  margin: 0 0.5%;
  line-height: 30px;
}
.tabitem.active {
  color: #f2be13;
}
.tab-content {
  width: 100%;
}
</style>
