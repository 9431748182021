<script>
import { getTeamAgentDetail } from "@/api/mine"
export default {
  data() {
    return {
      title: "我的历史",
      isBack: true,
      teamAgentDetail:''
    } 
  },
  created() {
    this.getTeamAgentDetail()
  },
  mounted() {
    document.title = this.title;
  },
  methods:{
    getTeamAgentDetail(){
      getTeamAgentDetail().then((res=>{
        this.teamAgentDetail = res.data
      }))
    }
  }
};
</script>

<template>
  <div class="bg">
    <div class="item">
      <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->
        <div class="bak">
            <div class="bitem">
                <p class="title">￥ {{ teamAgentDetail }}</p>
                <p class="text">历史总业绩</p>
            </div>
        </div>
        <p class="text2">团队奖励请到收支明细中查看</p>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../config/less/global.less";
.bg {
  width: 100%;
  height: 100vh;
  background: @base-bgColor;
}

p {
  margin: 0;
}

.bak{
    background-image: url('../../../assets/img/beijing.png');
    width: 100%;
    height: 100px;
    position: relative;
}

.bitem{
    position: absolute;
    top: 20%;
}

.bitem .title{
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    color: white;
    font-weight: 600;
}

.bitem .text{
    text-align: center;
    font-size: 14px;
    color: white;
}

.text2{
    margin-top: 10px;
    text-align: center;
    color: black;
}
</style>
