import request from "@/utils/request";

/**
 * 调用拉卡拉接口
 * @param query
 * @returns {*}
 */
// 调用拉卡拉接口
export function getLkl(data) {
  return request({
    url: "/user/order/lkl",
    method: "post",
    data: data,
  });
}
