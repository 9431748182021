<script>
export default {
  data() {
    return {
      title: "专属海报",
      cardname: "",
      activeName: "0",
      url: null,
    };
  },
  created() {
    this.cardname = this.$route.query.name;
    this.url = decodeURIComponent(this.$route.query.url);
    this.id = this.$route.query.id;
    console.log(this.cardname, this.url, this.id);
  },
  mounted() {
    document.title = this.cardname + this.title;
  },
  methods: {},
};
</script>

<template>
  <div class="bg">
    <div class="tabs">
      <div class="item">
        <img :src="url" />
        <p class="bottom-text">长按海报保存到相册</p>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../config/less/global.less";
.bg {
  width: 100%;
  height: 100vh;
  background: @base-bgColor;
}

p {
  margin: 0;
}

::v-deep .el-tabs__item {
  color: #6b6969;
  font-size: 16px;
  opacity: 0.5;
}

::v-deep .el-tabs__item.is-active {
  color: #f7a43a; //选中
  font-size: 20px;
  opacity: 1;
}

/* 下划线颜色 */
::v-deep .el-tabs__active-bar {
  background-color: #f7a43a;
}

.tabs {
  padding: 20px;
}

.item {
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
}

.item img {
  width: 100%;
  margin-top: 30px;
}

.item p {
  width: 100%;
  font-size: 17px;
  text-align: center;
  margin: 30px 0px;
  font-weight: 600;
}
</style>
