<script>
import { getMessageList } from "@/api/schedule"
export default {
  data() {
    return {
      title: "系统公告",
      isBack: true,
      list:[1,2,3,4,5,6,7,8,9,10,11],
      MessageList:[]
    };
  },
  created() {
    this.getMessageList()
  },
  mounted() {
    document.title = this.title;
  },
  methods: {
    getMessageList(){
      getMessageList().then((res)=>{
        this.MessageList = res.data
      })
    }
  },
};
</script>

<template>
  <div class="bg">
    <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->
    <div class="item">
      <el-collapse accordion>
        <el-collapse-item v-for="(item,index) in MessageList" :key="index">
            <!-- 自定义标题 -->
          <template slot="title">
            <div class="index">
              <p v-if="index < 9">0{{ index+1 }}</p>
              <p v-else>{{ index+1 }}</p>
            </div>
            <div class="yellow"></div>
            <div class="text">
              <p class="txt-title">{{ item.name }}</p>
              <p class="txt-time">{{ item.createTime }}</p>
            </div>
          </template>

          <div class="txttitle">
            {{ item.name }}
          </div>
          <div class="itemtxt">
            {{ item.context }}
          </div>

          <div class="txtbottom">
            <p>{{ item.name }}</p>
            <p>{{ item.createTime }}</p>
          </div>

        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../config/less/global.less";
.bg {
  width: 100%;
  height: 100vh;
  background: @base-bgColor;
}
.bak {
  background-image: url("../../../assets/img/beijing.png");
  width: 100%;
  height: 100px;
  position: relative;
}
.item{
    margin: 10px;
}
.index {
  width: 60px;
  // background-color: aqua;
  display: flex;
  flex-direction: row;
}
.index p {
  font-size: 25px;
  color: #fdd000;
  font-weight: 600;
  margin-left: 35%;
}
.yellow {
  height: 80%;
  width: 1px;
  background-color: #fdd000;
  float: right;
  box-sizing: border-box;
  margin: 10% 10px;
}

.text {
  display: flex;
  flex-direction: column;
}

.text p {
  line-height: 0px;
}

.txt-title {
  font-size: 16px;
}

.txttitle{
    width: 100%;
    text-align: center;
    margin: 10px 0px;
    color: red;
    font-size: 20px;
}

.itemtxt{
    margin: 5px 10px;
    font-size: 16px;
}

.txtbottom{
    position: relative;
    width: 200px;
    left: 65%;
    display: flex;
    flex-direction: column;
}

.txtbottom p{
    line-height: 0px;
    color: #666666;
    float: right;
}

</style>
