<script>
import { getbankBrandList } from "@/api/bankBrand";
import { getBankList } from "@/api/bank";
export default {
  data() {
    return {
      yellowCake: [1, 2, 3, 4, 5, 6],
      title: "推荐办卡",
      isBack: true,
      bankList: [],
      id: "",
      poster: [],
      params: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },

  created() {
    this.getBankList();
  },
  mounted() {
    document.title = this.title;
  },

  methods: {
    showGood(item) {
      let id = item.id
      this.getbankBrandList(id,item);
    },

    // 查找银行列表
    getBankList() {
      getBankList(this.params).then((res) => {
        console.log(res.data, "银行列表");
        this.bankList = res.data.records;
      });
    },

    //获取银行信息
    getbankBrandList(id,item) {
      getbankBrandList({ id }).then((res) => {
        console.log(res.data, "银行信息");
        this.$router.push({
        name: "PushCard",
        query: {
          id: item.id,
          name: item.bankName,
          url: encodeURIComponent(res.data.posterUrl),
        },
      });
        console.log(this.poster,'poster');
      });
    },
  },
};
</script>

<template>
  <div class="goods-item">
    <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->
    <div class="item" v-for="item in bankList" :key="item.id">
      <div class="item-detail">
        <!-- 银行图片 -->
        <img :src="item.bankLogo" alt="" />
        <!-- <img src="../../../assets/img/msyh.png" alt="" /> -->

        <!-- 银行名字 -->
        <div class="price" @click="showGood(item)">
          <span class="new-price">{{ item.bankName }}</span>
        </div>

        <!-- 优势 -->
        <p class="same">
          <span>- 1张 -</span>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* 盒子外部布局 */
.goods-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 1%;
  padding-bottom: 100px;
  background-color: #f6f6f6;
  box-shadow: 0px 0px 0px 300vh #f6f6f6;
}

.goods-item .item {
  margin-left: 1%;
  flex: 0 0 31.33%;
}

.goods-item .item:not(:nth-child(3n)) {
  margin-right: 1%;
}

.goods-item .item .item-detail {
  position: relative;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
  box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.goods-item .item .item-detail img {
  width: 90%;
  height: 80px;
  margin: 0 6%;
}

.btn {
  font-size: 14px;
  width: 100%;
  background-color: #ff7519;
  color: #ffffff;
  font-weight: 600;
  border-radius: 10px;
  padding: 3px 15px;
  display: flex;
  justify-content: center;
  border: none;
}

.desc {
  width: 100%;
  padding: 3px 0;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
}

.desc span {
  text-align: center;
  font-size: 10px;
  padding: 1px 2px;
  margin: 0px 5px;
  background-color: #fdf3d6;
  color: #0c0c0b;
}

.new-price {
  display: block;
  font-size: 20px;
  padding-bottom: 10px;
  color: #666666;
  font-weight: 500;
  text-align: center;
}

.same {
  display: flex;
  justify-content: center;
  margin: 3px 0px;
  /* padding-right: 5px;/ */
}
.same span:nth-of-type(1) {
  padding: 4px 8px 4px 8px;
  font-size: 14px;
  color: rgb(126, 126, 126);
}
.same span:nth-of-type(2) {
  padding: 4px 8px 4px 8px;
  background-color: rgba(202, 202, 202, 0.295);
  font-size: 12px;
  border-radius: 26px;
}

.trapezoid {
  position: absolute;
  width: 100%;
  top: -12%;
  left: -40%;
  border: 20px solid transparent;
  border-bottom-color: #ff6b6b;
  font-size: 10px;
  transform: rotate(-45deg);
  -webkit-background-clip: text;
  background-clip: text;
  white-space: nowrap;
}

.trapezoid span {
  /* display: block;
  margin-top: 10px; */
  position: absolute;
  top: 2.5px;
  left: 10%;
}

.text-gradient {
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: transparent;
  background: linear-gradient(to right, white, orange);
  -webkit-background-clip: text;
  background-clip: text;
  white-space: nowrap;
}
</style>
