<script>
import { orderList } from "@/api/order";
export default {
  data() {
    return {
      title: "结算进度",
      isBack: true,
      list: [1, 2, 3, 4],
      messageList: [],
    };
  },
  created() {
    this.orderList();
  },
  mounted() {
    document.title = this.title;
  },
  methods: {
    orderList() {
      orderList().then((res) => {
        this.messageList = res.data.records;
      });
    },
  },
};
</script>

<template>
  <div class="bg">
    <div class="item">
      <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->
      <div class="bak" v-for="(item, index) in messageList" :key="index">
        <div class="bak-ltft">
          <!-- <img src="../../../assets/img/yinh.jpg" alt="" /> -->
          <img :src="item.bankLogo" alt="" />
          <p>{{ item.bankName }}</p>
        </div>

        <div class="bak-center">
          <img src="../../../assets/img/line.jpg" alt="" />
        </div>

        <div class="bak-right">
          <p class="rightTitle">{{ item.transactionOrderNo }}</p>
          <p class="rightText">
            <img src="../../../assets/img/date1.png" />数据更新至:{{
              item.createTime
            }}
          </p>
          <p class="rightText">
            <img src="../../../assets/img/date.png" />下次更新在:实时
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../config/less/global.less";
.bg {
  width: 100%;
  height: 100vh;
  background: @base-bgColor;
}

p {
  margin: 0;
}

.bak {
  border-radius: 15px;
  background-color: #fff;
  width: 95%;
  height: 140px;
  margin: 10px 3%;
  box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.3);
  position: relative;
}

.bak-ltft {
  position: absolute;
  top: 10%;
  left: 3%;
  width: 18%;
  // background-color: black;
  height: 100px;
  color: black;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.bak-ltft img {
  width: 80%;
  height: 60px;
  margin: 3px 10px;
  justify-content: center;
}

.bak-center {
  position: absolute;
  top: 20%;
  left: 25%;
}

.bak-center img {
  width: 50%;
}

.bak-right {
  // background-color: bisque;
  position: absolute;
  width: 60%;
  height: 100px;
  top: 14%;
  left: 32%;
}

.bak-right p {
  margin: 7px;
}

.rightTitle {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 5px;
  overflow: hidden;
}

.rightText {
  height: 20%;
  overflow: hidden;
}

.rightText img {
  width: 7%;
  margin: 0px 10px 0px 0px;
}
</style>
