<script>
import { orderList } from "@/api/order";
export default {
  data() {
    return {
      title: "我的订单",
      isBack: true,
      activeName: "888",
      cardData: [], //全部信息
      cardlist: [], //筛选后的信息
      phoneNumber: "",
      searchPhone: "",
      statusList:[
        {status: 0 , Text:'待再查'},
        {status: 1 , Text:'失效订单'},
        {status: 2 , Text:'未通过'},
        {status: 3 , Text:'已完成'},
        {status: 4 , Text:'待激活'},
        {status: 5 , Text:'待首刷'},
        {status: 6 , Text:'二卡'},
        {status: 7 , Text:'已销卡'},
        {status: 999 , Text:'终审失败'},
      ],
    };
  },
  created() {
    this.orderList()
  },
  watch: {
    searchPhone(newVal) {
      this.filterByPhone(newVal);
    },
  },
  mounted() {
    document.title = this.title;
  },
  methods: {

    filterByPhone(phoneNumber) {
      // 使用filter方法根据手机号筛选数据
      this.cardlist = this.cardData.filter((item) => {
        return  item.phone.includes(phoneNumber);
      });
    },

    async handleClick(tab, event) {
      this.activeName = tab.name; // 更新当前活跃标签
      this.cardlist = await this.filterDataByTab(+tab.name);
      
    },

    filterDataByTab(tabName) {
      if (tabName === 888) {
        return this.cardData;
      } else{
        return this.cardData.filter((item) => {
          return item.state === tabName;
        });
      }
    },

    orderList(){
      orderList().then((res=>{
        console.log(res.data.records);
        this.cardData = res.data.records
        this.cardlist = this.cardData
      }))
    },
    callPhone(phoneNumber) {
      //window.location.href = "tel://158xxxx1317";
      window.location.href = "tel:" + phoneNumber;
    },

  },
};
</script>

<template>
  <div class="bg">
    <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->
    <div class="item">
      <el-input placeholder="请输入手机号" v-model="searchPhone">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部订单" name="888">
          <div class="monaditem" v-for="item in cardlist">
            <div class="item-top">
              <p class="top-title">{{ item.bankName }}</p>
              <p class="cardstatus" v-for="state in statusList" v-if="state.status === item.state">{{ state.Text }}·</p>
            </div>

            <div class="item-center">
              <p>
                {{ item.nameOfCardholder }} | {{ item.phone }}
                <img src="../../../assets/img/phone.png" @click="callPhone(item.phone)" />
              </p>
              <p>订单号：{{ item.transactionOrderNo }}</p>
              <p>申请时间：{{ item.applyForTime }}</p>
            </div>

            <div class="item-bottom">
              <img src="../../../assets/img/wenh.png" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="待再查" name= "0">
          <div class="monaditem" v-for="item in cardlist">
            <div class="item-top">
              <p class="top-title">{{ item.bankName }}</p>
              <p class="cardstatus" v-for="state in statusList" v-if="state.status === item.state">{{ state.Text }}·</p>
            </div>

            <div class="item-center">
              <p>
                {{ item.nameOfCardholder }} | {{ item.phone }}
                <img src="../../../assets/img/phone.png" @click="callPhone(item.phone)" />
              </p>
              <p>订单号：{{ item.transactionOrderNo }}</p>
              <p>申请时间：{{ item.applyForTime }}</p>
            </div>

            <div class="item-bottom">
              <img src="../../../assets/img/wenh.png" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="失效订单" name="1">
          <div class="monaditem" v-for="item in cardlist">
            <div class="item-top">
              <p class="top-title">{{ item.bankName }}</p>
              <p class="cardstatus" v-for="state in statusList" v-if="state.status === item.state">{{ state.Text }}·</p>
            </div>

            <div class="item-center">
              <p>
                {{ item.nameOfCardholder }} | {{ item.phone }}
                <img src="../../../assets/img/phone.png" @click="callPhone(item.phone)" />
              </p>
              <p>订单号：{{ item.transactionOrderNo }}</p>
              <p>申请时间：{{ item.applyForTime }}</p>
            </div>

            <div class="item-bottom">
              <img src="../../../assets/img/wenh.png" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="未通过" name="2">
          <div class="monaditem" v-for="item in cardlist">
            <div class="item-top">
              <p class="top-title">{{ item.bankName }}</p>
              <p class="cardstatus" v-for="state in statusList" v-if="state.status === item.state">{{ state.Text }}·</p>
            </div>

            <div class="item-center">
              <p>
                {{ item.nameOfCardholder }} | {{ item.phone }}
                <img src="../../../assets/img/phone.png" @click="callPhone(item.phone)" />
              </p>
              <p>订单号：{{ item.transactionOrderNo }}</p>
              <p>申请时间：{{ item.applyForTime }}</p>
            </div>

            <div class="item-bottom">
              <img src="../../../assets/img/wenh.png" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已完成" name="3">
          <div class="monaditem" v-for="item in cardlist">
            <div class="item-top">
              <p class="top-title">{{ item.bankName }}</p>
              <p class="cardstatus" v-for="state in statusList" v-if="state.status === item.state">{{ state.Text }}·</p>
            </div>

            <div class="item-center">
              <p>
                {{ item.nameOfCardholder }} | {{ item.phone }}
                <img src="../../../assets/img/phone.png" @click="callPhone(item.phone)" />
              </p>
              <p>订单号：{{ item.transactionOrderNo }}</p>
              <p>申请时间：{{ item.applyForTime }}</p>
            </div>

            <div class="item-bottom">
              <img src="../../../assets/img/wenh.png" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="待激活" name="4">
          <div class="monaditem" v-for="item in cardlist">
            <div class="item-top">
              <p class="top-title">{{ item.bankName }}</p>
              <p class="cardstatus" v-for="state in statusList" v-if="state.status === item.state">{{ state.Text }}·</p>
            </div>

            <div class="item-center">
              <p>
                {{ item.nameOfCardholder }} | {{ item.phone }}
                <img src="../../../assets/img/phone.png" @click="callPhone(item.phone)" />
              </p>
              <p>订单号：{{ item.transactionOrderNo }}</p>
              <p>申请时间：{{ item.applyForTime }}</p>
            </div>

            <div class="item-bottom">
              <img src="../../../assets/img/wenh.png" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="待首刷" name="5">
          <div class="monaditem" v-for="item in cardlist">
            <div class="item-top">
              <p class="top-title">{{ item.bankName }}</p>
              <p class="cardstatus" v-for="state in statusList" v-if="state.status === item.state">{{ state.Text }}·</p>
            </div>

            <div class="item-center">
              <p>
                {{ item.nameOfCardholder }} | {{ item.phone }}
                <img src="../../../assets/img/phone.png" @click="callPhone(item.phone)" />
              </p>
              <p>订单号：{{ item.transactionOrderNo }}</p>
              <p>申请时间：{{ item.applyForTime }}</p>
            </div>

            <div class="item-bottom">
              <img src="../../../assets/img/wenh.png" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="二卡" name="6">
          <div class="monaditem" v-for="item in cardlist">
            <div class="item-top">
              <p class="top-title">{{ item.bankName }}</p>
              <p class="cardstatus" v-for="state in statusList" v-if="state.status === item.state">{{ state.Text }}·</p>
            </div>

            <div class="item-center">
              <p>
                {{ item.nameOfCardholder }} | {{ item.phone }}
                <img src="../../../assets/img/phone.png" @click="callPhone(item.phone)" />
              </p>
              <p>订单号：{{ item.transactionOrderNo }}</p>
              <p>申请时间：{{ item.applyForTime }}</p>
            </div>

            <div class="item-bottom">
              <img src="../../../assets/img/wenh.png" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已销卡" name="7">
          <div class="monaditem" v-for="item in cardlist">
            <div class="item-top">
              <p class="top-title">{{ item.bankName }}</p>
              <p class="cardstatus" v-for="state in statusList" v-if="state.status === item.state">{{ state.Text }}·</p>
            </div>

            <div class="item-center">
              <p>
                {{ item.nameOfCardholder }} | {{ item.phone }}
                <img src="../../../assets/img/phone.png" @click="callPhone(item.phone)" />
              </p>
              <p>订单号：{{ item.transactionOrderNo }}</p>
              <p>申请时间：{{ item.applyForTime }}</p>
            </div>

            <div class="item-bottom">
              <img src="../../../assets/img/wenh.png" alt="" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="终审失败" name="999 ">
          <div class="monaditem" v-for="item in cardlist">
            <div class="item-top">
              <p class="top-title">{{ item.bankName }}</p>
              <p class="cardstatus" v-for="state in statusList" v-if="state.status === item.state">{{ state.Text }}·</p>
            </div>

            <div class="item-center">
              <p>
                {{ item.nameOfCardholder }} | {{ item.phone }}
                <img src="../../../assets/img/phone.png" @click="callPhone(item.phone)" />
              </p>
              <p>订单号：{{ item.transactionOrderNo }}</p>
              <p>申请时间：{{ item.applyForTime }}</p>
            </div>

            <div class="item-bottom">
              <img src="../../../assets/img/wenh.png" alt="" />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <p class="text">——我也是有底线的——</p>
  </div>
</template>

<style lang="less" scoped>
@import "../../../config/less/global.less";
.bg {
  width: 100%;
  height: 100vh;
  background: @base-bgColor;
}

p {
  margin: 0;
}

.item {
  margin: 10px;
}

::v-deep .el-tabs__item {
  color: #6b6969;
  font-size: 14px;
  opacity: 0.5;
}

::v-deep .el-tabs__item.is-active {
  color: #f7a43a; //选中
  font-size: 18px;
  opacity: 1;
}

/* 下划线颜色 */
::v-deep .el-tabs__active-bar {
  background-color: #f7a43a;
}

.monaditem {
  margin: 15px auto;
  width: 98%;
  height: 175px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2),
    0 5px 5px -5px rgba(0, 0, 0, 0.2);
}

.item-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 3%;
}

.top-title {
  font-size: 18px;
  margin: 3% 0 0 3%;
  font-weight: 600;
}

.cardstatus {
  color: #f69d2c;
  font-weight: 600;
  font-size: 18px;
  margin: 3% 3% 0 0;
}

.item-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 3px 6%;
  font-size: 14px;
  color: #7f7f7f;
}

.item-center p {
  line-height: 35px;
}

.item-center img {
  width: 18px;
  margin-left: 10px;
}

.item-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 3px 6%;
  color: #7f7f7f;
}

.item-bottom img {
  width: 5%;
}

.text {
  padding: 30px 0px;
  text-align: center;
  color: #939393;
  position: absolute;
  //   height: 10px;
}
</style>
