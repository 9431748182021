<script>
export default {
  props: {
    goods: {
      type: Array,
      default: {},
    },
  },
  data() {
    return {
      yellowCake: [],
    };
  },
  methods: {
    showGood(item) {
      this.$router.push({
        name: "GoodsDetail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<template>
  <div class="goods-item">
    <div
      class="item"
      v-for="item in goods"
      :key="item.id"
      @click="showGood(item)"
    >
      <div class="item-detail">
        <!-- 左上角轧带 -->
        <div class="trapezoid">
          <span>{{ item.labelTwo }}</span>
        </div>

        <!-- 银行图片 -->
        <img :src="item.bankLogo" alt="" />
        <!-- <img src="../assets/img/card1.jpeg" alt="" /> -->

        <!-- 银行名字 -->
        <div class="price">
          <span class="new-price">{{ item.bankName }}</span>
        </div>

        <!-- 黄块块 -->
        <div class="desc">
          <template>
            <span>{{ item.label }}</span>
            <span>{{ item.labelOne }}</span>
          </template>
        </div>

        <!-- 优势 -->
        <p class="same">
          <span>{{ item.labelThree }}</span>
        </p>

        <button class="btn">奖金￥{{ item.reward }}</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* 盒子外部布局 */
.goods-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 120px;
}

.goods-item .item {
  margin-left: 1%;
  flex: 0 0 31.33%;
  width: calc((100% / 3) - 2%);
  box-shadow: 5px 0 3px -5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.goods-item .item:not(:nth-child(3n)) {
  margin-right: 1%;
}

.goods-item .item .item-detail {
  position: relative;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.goods-item .item .item-detail img {
  width: 70%;
  height: 75px;
  margin-bottom: 10px;
}

.btn {
  font-size: 14px;
  width: 100%;
  background-color: #ff7519;
  color: #ffffff;
  font-weight: 600;
  border-radius: 10px;
  padding: 3px 15px;
  display: flex;
  justify-content: center;
  border: none;
  overflow: hidden;
  flex-wrap: nowrap;
}

.desc {
  width: 100%;
  height: 15px;
  padding: 3px 0;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
}

.desc span {
  text-align: center;
  width: 30px;
  height: 15px;
  font-size: 10px;
  padding: 2px 3px;
  margin: 0px 5px;
  border-radius: 5px;
  background-color: #fdf3d6;
  color: #957716;
}

.new-price {
  display: block;
  font-size: 16px;
  color: black;
  font-weight: 600;
  text-align: center;
  margin: -10% 0 3% 0;
}

.same {
  display: flex;
  justify-content: center;
  margin: 3px 0px;
}

.same span:nth-of-type(1) {
  padding: 4px 8px 4px 8px;
  font-size: 14px;
  color: rgb(126, 126, 126);
}
.same span:nth-of-type(2) {
  padding: 4px 8px 4px 8px;
  background-color: rgba(202, 202, 202, 0.295);
  font-size: 12px;
  border-radius: 26px;
}

.trapezoid {
  position: absolute;
  width: 50px;
  top: 0;
  left: 0;
  margin-left: -23%;
  padding-right: 7px;
  border: 15px solid transparent;
  border-bottom-color: #f9c87c;
  transform: rotate(-45deg);
}

.trapezoid span {
  position: absolute;
  display: flex;
  justify-content: space-around;
  font-size: 9px;
  top: 1.5px;
  /* bottom: 0px; */
  left: 14%;
  right: 14%;
  color: #ffffff;
}
</style>
