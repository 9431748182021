<script>
export default {
  data() {
    return{
        title: "活动中心",
        isBack: true,
    }
  },
  created() {
    
  },
  mounted() {
    document.title = this.title;
  },
  methods:{

  }
};
</script>

<template>
    <div class="bg">
        <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->
        <img src="../../../assets/img/background/huod1.jpeg" alt="">
    </div>
</template>

<style lang="less" scoped>
// 看 vue.config.js
@import "../../../config/less/global.less";
.bg {
  width: 100%;
  height: 100vh;
  background: yellow;
  background: @base-bgColor;
}
p {
  margin: 0;
}

img{
    width: 100%;
}

</style>
