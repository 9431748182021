<script>
import {
  getAccountLogSumList,
  getAgentAccountLog,
  withdraworder,
} from "@/api/withdrawalDetails";
import { getUserInfo } from "@/api/login";
export default {
  name: "withdraw",
  data() {
    return {
      title: "立即提现",
      isBack: true,
      activeTab: "completed",
      amounts: ["30", "50", "100", "200"],
      selectedAmount: "",
      activeIndex: null,
      tips: [
        "请确认输入正确的提现金额;",
        "结算金额不能低于1元;",
        "平台每日结算一次(以每日下午6点至次日下午6点为一周期),每日下午6点结算上一周期提现佣金,周五下午6点以后提现平台结算顺延至次周周一(非工作日);",
        "务必填写有效的姓名、手机号、银行帐号以保证提现正常;",
        "收款人年龄需在18-65之间;",
      ],
      MyagentList: [],
      isBankCardBound: null,
      showAlert: false,
      alertMessage: "",
      dataList: {
        transMobile: "", //手机号
        transSettleCard: "", //交易人卡号
        transIdentityCard: "", //交易人身份证号
        transAmt: "", //交易金额
        transRealName: "", //交易人名称
      },
    };
  },
  created() {
    this.getAccountLogSumList();
    this.getUserInfo();
  },
  mounted() {
    document.title = this.title;
  },
  methods: {
    //获取金额
    getAccountLogSumList() {
      getAccountLogSumList().then((res) => {
        this.MyagentList = res.data;
      });
    },

    //选中金额index更新
    setActiveTab(tab) {
      this.activeTab = tab;
    },

    //金额更新
    setActiveIndex(index) {
      this.activeIndex = index;
      this.dataList.transAmt = this.amounts[index]; // 更新选中的金额到input中（如果需要）
    },

    //提现控制
    checkInput() {
      if (this.dataList.transAmt < 1) {
        alert('提现金额最少为1元')
      } else if (this.isBankCardBound === 0) {
        this.alertMessage = "请先完善提现信息";
        alert('请先完善提现信息')
        this.hideAlert()
      } else if (this.dataList.transAmt <= this.MyagentList.balance) {
        // 提交表单
        // console.log("提交表单:", this.dataList);
        this.withdraworder(this.dataList);

      } else {
        this.alertMessage = "提现金额超出余额";
        alert('提现金额超出余额')
      }
    },

    hideAlert() {
      if (!this.isBankCardBound && this.alertMessage === "请先完善提现信息") {
        this.showAlert = false;
        console.log("跳转到绑定银行卡页面");
        this.$router.push({
          name: "BindingCard",
        });
      } else {
        this.showAlert = false;
      }
    },

    //获取登录人信息
    getUserInfo() {
      getUserInfo().then((res) => {
        console.log(res);
        this.isBankCardBound = res.data.isRealName;
        this.dataList.transMobile = res.data.agentMobile;
        this.dataList.transSettleCard = res.data.transSettleCard;
        this.dataList.transIdentityCard = res.data.transIdentityCard;
        this.dataList.transRealName = res.data.transRealName;
      });
    },

    withdraworder(list) {
      console.log(list);
      withdraworder(list).then((res) => {
        if (res.code === 200) {
          this.getAccountLogSumList()
          alert(res.msg)
          this.dataList.transAmt = ''
        }
      });
    },
  },
};
</script>

<template>
  <div class="bg">
    <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->

    <div class="bakTwo"></div>

    <!-- 黑框 -->
    <div class="item">
      <div class="itemlist">
        <p class="itemlist-p1" v-if="
          MyagentList.balance === '' ||
          MyagentList.balance === null ||
          MyagentList.balance === undefined
        ">
          0
        </p>
        <p class="itemlist-p1" v-else>{{ MyagentList.balance }}</p>
        <p class="itemlist-p2">当前余额</p>
      </div>
      <div class="itemlist">
        <p class="itemlist-p1" v-if="
          MyagentList.amountSum === '' ||
          MyagentList.amountSum === null ||
          MyagentList.amountSum === undefined
        ">
          0
        </p>
        <p class="itemlist-p1" v-else>{{ MyagentList.amountSum }}</p>
        <p class="itemlist-p2">累计收入</p>
      </div>
      <div class="itemlist">
        <p class="itemlist-p1" v-if="
          MyagentList.settled === '' ||
          MyagentList.settled === null ||
          MyagentList.settled === undefined
        ">
          0
        </p>
        <p class="itemlist-p1" v-else>{{ MyagentList.settled }}</p>
        <p class="itemlist-p2">已结算</p>
      </div>
    </div>

    <!-- 提现方式 -->
    <div class="inputK">
      <div class="titleYellow">
        <div class="yellowblock"></div>
        <span class="title">提现方式</span>
      </div>
      <img src="../../../assets/img/cardzhuan.png" alt="" />
    </div>

    <!-- 提现金额 -->
    <div class="output">
      <div class="titleYellow">
        <div class="yellowblock"></div>
        <span class="title">提现金额</span>
      </div>
      <div class="outputitem">
        <div class="outputk">
          <span>￥</span>
          <input type="text" v-model="dataList.transAmt" placeholder="请输入提现金额" />
        </div>
        <div class="huixian"></div>
        <div class="outputbtn">
          <button v-for="(amount, index) in amounts" :key="index" @click="setActiveIndex(index)"
            :class="{ active: activeIndex === index }">
            {{ amount }}元
          </button>
        </div>
      </div>
    </div>

    <!-- 注意事项-->
    <div class="tips">
      <div class="titleYellow">
        <div class="yellowblock"></div>
        <span class="title">注意事项</span>
      </div>
      <div class="tip-p">
        <p v-for="(item, index) in tips" :key="index">
          {{ index + 1 }}、{{ item }}
        </p>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="bottomChat">
      <div class="share-content">
        <div class="phoneBtn" @click="checkInput">去提现</div>
      </div>
    </div>

    <!-- <div v-if="showAlert" class="alert-box">
      <p class="title">{{ alertMessage }}</p>
      <button @click="hideAlert">
        <p>确定</p>
      </button>
    </div> -->
  </div>
</template>

<style lang="less" scoped>
// 看 vue.config.js
@import "../../../config/less/global.less";

.bg {
  width: 100%;
  // height: 100vh;
  background: yellow;
  background: @base-bgColor;
  padding-bottom: 100px;
}

p {
  margin: 0;
}

.bakTwo {
  z-index: -1000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f6f6f6;
}

.alert-box {
  position: absolute;
  top: 50%;
  left: 20%;
  width: 50%;
  padding: 10px;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.alert-box .title {
  position: relative;
  color: white;
  text-align: center;
  width: 100%;
}

.alert-box button {
  width: 30%;
  margin-top: 30px;
  margin: 20px 0 0 60%;
  background-color: #f8f8f8;
  color: black;
  padding: 5px 0;
  border: none;
}

.huixian {
  width: 100%;
  margin: 10px 0;
  height: 1px;
  background-color: black;
}

.output {
  position: relative;
  width: 99%;
  height: 200px;
  margin-top: 5px;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: row;
}

.tips {
  position: relative;
  width: 99%;
  // height: 100px;
  padding: 40px 0px;
  margin-top: 5px;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
}

.tip-p {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
}

.tip-p p {
  line-height: 30px;
}

.outputitem {
  position: absolute;
  top: 34%;
  left: 5%;
  width: 90%;
  height: 130px;
  // background-color: #ffd866;
}

.outputk {
  width: 99%;
  height: 30px;
  margin-top: 1px;
  display: flex;
  flex-direction: row;
}

.outputk input {
  border: none;
  width: 80%;
  margin: 0px 5%;
}

.outputk span {
  display: flex;
  line-height: 30px;
  color: #ffd866;
  font-size: 20px;
  font-weight: 600;
}

.yellowblock {
  // position: absolute;
  width: 2%;
  height: 35px;
  background-color: #ffd866;
}

.titleYellow {
  display: flex;
  flex-direction: row;

}

.outputbtn {
  width: 100%;
}

.outputbtn button {
  width: 23%;
  margin: 10px 3px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fdfdfd;
  border: #918f8f solid 1px;
}

.outputbtn button.active {
  border: #ffd866 solid 2px;
}

.bottomChat {
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.share-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.phoneBtn {
  width: 100%;
  height: 35px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  background-color: #ffd866;
}

.item {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.itemlist {
  width: 33%;
  height: 100px;
  background-color: black;
  margin: 0px 0.1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.itemlist-p1,
.itemlist-p2 {
  text-align: center;
  margin-bottom: 5px;
}

.itemlist-p1 {
  color: #ffd866;
  font-size: 20px;
  font-weight: 600;
}

.itemlist-p2 {
  color: #f8f8f8;
  font-size: 12px;
}

.inputK {
  position: relative;
  width: 99%;
  height: 100px;
  margin-top: 5px;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: row;
}

.title {
  position: absolute;
  top: 10%;
  left: 7%;
  display: flex;
  line-height: 30px;
  // color: #ffd866;
  font-size: 18px;
}

.inputK img {
  position: absolute;
  top: 50%;
  left: 5%;
  width: 43%;
  height: 40%;
}

.tab {
  width: 99%;
  height: 30px;
  margin-top: 2px;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: row;
  border: 1px solid #eeeeee;
}

.tabitem {
  width: 49%;
  text-align: center;
  margin: 0 0.5%;
  line-height: 30px;
}

.tab-content {
  width: 100%;
}
</style>
