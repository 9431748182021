import request from "@/utils/request";

/**
 * 客户管理
 * @param query
 * @returns {*}
 */
// 客户管理
export function teamAgent(query) {
  return request({
    url: "/user/myagent/teamAgent",
    method: "get",
    params: query
  });
}
