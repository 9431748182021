import request from '@/utils/request'

/**
 * 点进后
 * @param query
 * @returns {*}
 */
// 查询参数列表
export function getbankBrandList(query) {
    return request({
        url: '/user/bankBrand/getBy',
        method: 'get',
        params: query
    })
}