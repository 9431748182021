<script>
import {getBannerList} from "@/api/banner";
import {getBankList} from "@/api/bank";
import {wxLogin} from "@/api/login";
import GoodsItem from "../../components/goodItem.vue";
import {setToken} from "@/utils/auth";
import {getMyagentList} from "@/api/mine";

export default {
  components: {GoodsItem},
  data() {
    return {
      title: "首页",
      isBack: false,
      bannerList: [],
      bankList: [],
      params: {
        pageNum: 1,
        pageSize: 999,
      },
      erweima: {
        loginType: "",
        agentCode: "",
        bankId: "",
      },
    };
  },
  created() {
    this.getMyagentList()

    this.loginIf();

    // 查询轮播图列表
    // 查找银行列表
    // this.$router.replace({ name: "Login" });
    // this.getBannerList();
    // this.getBankList();
  },
  mounted() {
    document.title = this.title;
  },
  methods: {
    // //登录跳转
    async loginIf() {
      const queryParams = new URLSearchParams(window.location.search);
      this.erweima.loginType = queryParams.get("loginType");
      this.erweima.agentCode = queryParams.get("agentCode");
      this.erweima.bankId = queryParams.get("bankId");
      const appid = "wx4cb24a6257a81b41"; //公众号的appid

      //判定用户是扫推卡码进来的 还是正常登录注册的
      if (this.erweima.loginType === "tk") {
        console.log("推卡跳转");
        this.$router.replace({
          path: "/Shenka",
          query: {
            loginType: this.erweima.loginType,
            agentCode: this.erweima.agentCode,
            bankId: this.erweima.bankId,
          },
        });
      } else {

        const callback_url = encodeURIComponent(window.location.href);

        // 获取链接参数
        let data = this.GetRequest();
        console.log(data, "这是data 主界面");
        if (data.code === "" || data.code == null) {
          console.log(data, "判断data.code不存在");
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${callback_url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        } else {
          if (!localStorage.getItem("hasLoggedIn")) {
            // 登录的逻辑'
            wxLogin({code: data.code}).then((res) => {
              console.log(res, "触发微信登录");

              let state = res.status;
              let restore = res.token;

              //判定用户是否注册 1为未注册 0为已注册
              if (state === "1") {
                console.log(state);
                console.log("用户未注册");
                window.location.href = "http://tktest.yiqichuangying.com/#/SignIn";
              }

              setToken(restore);
              //查询轮播图列表
              this.getBannerList();
              // 查找银行列表
              this.getBankList();

              // 设置已登录的标记
              localStorage.setItem("hasLoggedIn", "true");
            });
          }
          //查询轮播图列表
          this.getBannerList();
          // 查找银行列表
          this.getBankList();
        }
      }
    },

    GetRequest() {
      //url例子：www.bicycle.com?id="123456"&Name="bicycle"；
      var url = decodeURI(location.href); //?id="123456"&Name="bicycle";
      var object = {};
      if (url.indexOf("?") != -1) {
        //url中存在问号，也就说有参数。
        var index = url.indexOf("?");
        var str = url.substring(index + 1); //得到?后面的字符串
        var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",Name="bicycle"];
        for (var i = 0; i < strs.length; i++) {
          object[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return object;
    },

    // 查询轮播图列表
    getBannerList() {
      getBannerList().then((res) => {
        this.bannerList = res.data;
      });
    },

    // 查找银行列表
    getBankList() {
      getBankList(this.params).then((res) => {
        this.bankList = res.data.records;
      });
    },

    //个人中心获取信息
    getMyagentList() {
      getMyagentList().then((res) => {
        console.log(res, "个人中心获取");
        let code = res.code;
        if (code != 200) {
          localStorage.removeItem("Admin-Token");
          localStorage.removeItem("hasLoggedIn");
          window.location.href = "http://tktest.yiqichuangying.com/";
        }
      });
    },
  },
};
</script>

<template>
  <div class="bg">
    <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->

    <!-- 轮播图 接入后端 -->
    <div class="lunbo">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in bannerList" :key="item.id">
          <img :src="item.url" />
        </van-swipe-item>

        <!--<van-swipe-item>-->
        <!--  <img src="../../assets/img/background/bak1.png"/>-->
        <!--</van-swipe-item>-->
      </van-swipe>
    </div>

    <div class="title">
      <hr/>
      <span> 热门银行 <img src="../../assets/img/hot.png" alt=""/> </span>
    </div>

    <!-- 商品卡片 接入后端 -->
    <div class="card-list">
      <GoodsItem :goods="bankList"></GoodsItem>
    </div>
  </div>
</template>

<style lang="less" scoped>
// 看 vue.config.js
@import "../../config/less/global.less";

.bg {
  width: 100%;
  height: 100%;
  background: yellow;
  background: @base-bgColor;
  padding-bottom: 100px;
}

.title {
  width: 110px;
  margin: 10px 0px 10px 20px;
}

.title span {
  display: block;
  // margin-top: -7px;
  font-size: 16px;
  font-weight: 500;
}

.title span img {
  width: 16px;
}

.lunbo {
  width: 100%;
}

.my-swipe {
  margin: 10px;
  width: 95%;
  height: 180px;
}

.my-swipe .van-swipe-item {
  color: #fff;
  padding: 0;
  text-align: center;
  background-color: #ffffff;
}

.my-swipe .van-swipe-item img {
  width: 100%;
  height: 100%;
}

.my-swipe1 .van-swipe-item {
  color: #fff;
  text-align: center;
  background-color: #ffffff;
}

/* ------------ogo0ds */
.card-list {
  width: 100%;
  border-radius: 5px;
  padding-bottom: 100px;
}
</style>
