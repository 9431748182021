<script>
import { getPoster } from "@/api/inviter";
export default {
  data() {
    return {
      title: "推广海报",
      picList: [],
      activeName: "0",
    };
  },
  created() {
    this.getPoster()
  },
  mounted() {
    document.title = this.title;
  },
  methods: {
    async handleClick(tab, event) {
      console.log(typeof tab.name);
      this.activeName = tab.name; // 更新当前活跃标签
    },
    getPoster(){
      getPoster().then((res)=>{
        console.log(res.data);
        this.picList = res.data.posterList
      })
    }
  },
};
</script>

<template>
  <div class="bg">
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- 标签1 -->
        <el-tab-pane label="合伙人" name="0">
          <div class="item">
            <img
              :src="item"
              v-for="(item, index) in picList"
              v-if="index === +activeName"
            />
            <p class="bottom-text">长按海报保存到相册</p>
          </div>
        </el-tab-pane>

        <!-- 标签2 -->
        <el-tab-pane label="合伙人" name="1">
          <div class="item">
            <img
              :src="item"
              v-for="(item, index) in picList"
              v-if="index === +activeName"
            />
            <p class="bottom-text">长按海报保存到相册</p>
          </div>
        </el-tab-pane>

        <!-- 标签3 -->
        <el-tab-pane label="合伙人" name="2">
          <div class="item">
            <img
              :src="item"
              v-for="(item, index) in picList"
              v-if="index === +activeName"
            />
            <p class="bottom-text">长按海报保存到相册</p>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../config/less/global.less";
.bg {
  width: 100%;
  height: 100vh;
  background: @base-bgColor;
}

p {
  margin: 0;
}

::v-deep .el-tabs__item {
  color: #6b6969;
  font-size: 16px;
  opacity: 0.5;
}

::v-deep .el-tabs__item.is-active {
  color: #f7a43a; //选中
  font-size: 20px;
  opacity: 1;
}

/* 下划线颜色 */
::v-deep .el-tabs__active-bar {
  background-color: #f7a43a;
}

.tabs {
  padding: 20px;
}

.item{
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
}

.item img{
  width: 100%;
  margin-top: 30px;
}

.item p{
  width: 100%;
  font-size: 17px;
  text-align: center;
  margin: 30px 0px;
  font-weight: 600;
}
</style>
