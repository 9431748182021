<script>
import { getBannerList } from "@/api/banner";
import GoodsItem from "../../components/goodItem.vue";
import { wxLogin } from "@/api/login";
export default {
  components: { GoodsItem },
  data() {
    return {
      title: "礼品",
      isBack: false,
      bannerList: [1],
      value: "",
      user: {},
      navinfo: [],
      navinfo2: [],
      goods: [],
    };
  },
  created() {
    this.login()
  },
  mounted() {
    document.title = this.title;
  },
  methods: {
    //登录跳转
    login() {
      // console.log("登录");
      const appid = "wx4cb24a6257a81b41"; //公众号的appid
      const callback_url = encodeURIComponent(window.location.href); // 获取当前地址，跳转至以下腾讯地址会携带code重定向到该地址
      console.log(callback_url);
      let data = this.GetRequest(); // 获取链接参数
      if (data.code === "" || data.code == null) {
        // console.log(data.code);
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${callback_url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      } else {
        if (!localStorage.getItem("Admin-Token")) {
          // 登录的逻辑
          wxLogin({ code: data.code })
            .then((res) => {
              localStorage.setItem(res.token);
              console.log(res.token);
              if (res.data.code == 1) {
                this.$router.replace({
                  path: "/SignIn"
                });
              } else if (res.toke) {
                //查询轮播图列表
                this.getBannerList();
              }
            })
            .catch((err) => {
              // 跳转注册页面
              this.$router.replace({
                path: "/SignIn",
                query: { code: data.code },
              });
            });
        } else {
          //查询轮播图列表
          this.getBannerList();
        }
      }
    },
    GetRequest() {
      //url例子：www.bicycle.com?id="123456"&Name="bicycle"；
      var url = decodeURI(location.href); //?id="123456"&Name="bicycle";
      var object = {};
      if (url.indexOf("?") != -1) {
        //url中存在问号，也就说有参数。
        var index = url.indexOf("?");
        var str = url.substring(index + 1); //得到?后面的字符串
        var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",Name="bicycle"];
        for (var i = 0; i < strs.length; i++) {
          object[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return object;
    },
    // 查询轮播图列表
    getBannerList() {
      getBannerList().then((res) => {
        this.bannerList = res.data;
      });
    },
  },
};
</script>

<template>
  <div class="bg">
    <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->

    <!-- 轮播图 接入后端 -->
    <div class="lunbo">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in bannerList" :key="item.id">
          <!-- <img :src="item.url" alt="图"/>  -->
          <img src="../../assets/img/background/bak3.jpeg" alt="图" />
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="title">
      <hr />
      <span> 热门银行 <img src="../../assets/img/hot.png" alt="" /> </span>
    </div>
  </div>
</template>

<style lang="less" scoped>
// 看 vue.config.js
@import "../../config/less/global.less";
.bg {
  width: 100%;
  height: 100%;
  background: yellow;
  background: @base-bgColor;
}

.title {
  width: 110px;
  margin: 10px 0px 10px 20px;
}

.title span {
  display: block;
  // margin-top: -7px;
  font-size: 16px;
  font-weight: 500;
}

.title span img {
  width: 16px;
}

.lunbo {
  width: 100%;
}
.my-swipe {
  margin: 10px;
  width: 95%;
  height: 180px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  padding: 0;
  text-align: center;
  background-color: #ffffff;
}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: 100%;
}
.my-swipe1 .van-swipe-item {
  color: #fff;
  text-align: center;
  background-color: #ffffff;
}
/* ------------ogo0ds */
.card-list {
  width: 100%;
  border-radius: 5px;
}
</style>
