import request from '@/utils/request'

/**
 * 首页 银行
 * @param query
 * @returns {*}
 */
// 查询参数列表
export function getBankList(query) {
    return request({
        url: '/user/bankBrand/list',
        method: 'get',
        params: query
    })
}
