import request from '@/utils/request'

/**
 * 结算进度
 * @param query
 * @returns {*}
 */
// 结算进度
export function getMessageList(query) {
    return request({
        url: '/user/message/getMessageList',
        method: 'get',
        params: query
    })
}
