<script>
import { getsendSms, addWithdrawOrder } from "@/api/bindingCard";
export default {
  name: "withdraw",
  data() {
    return {
      title: "银行卡信息绑定",
      isBack: true,
      activeTab: "completed",
      isBankCardBound: false, // 银行卡是否绑定
      showAlert: false,
      alertMessage: "",
      isCounting: false,
      countdown: 60,
      dataList: {
        transRealName: "",
        transIdentityCard: "",
        transMobile: "",
        transSettleCard: "",
        code: "",
        uuid: "",
      },
    };
  },
  created() { },
  mounted() {
    document.title = this.title;
  },

  methods: {
    // &&
    // this.dataList.uuid
    //提交信息
    checkInput() {
      if (
        this.dataList.transRealName &&
        this.dataList.transIdentityCard.length === 18 &&
        this.dataList.transMobile.length === 11 &&
        this.dataList.code &&
        this.dataList.transSettleCard &&
        this.dataList.uuid
      ) {
        // 提交表单逻辑...
        // console.log(this.dataList);
        this.addWithdrawOrder(this.dataList);
      } else {
        this.alertMessage = "请将信息补全后再提交";
        alert('请将信息补全后再提交')
      }
    },

    //验证码
    async getVerificationCode() {
      if (this.isCounting) return;

      if (this.dataList.transMobile) {
        this.isCounting = true;
        this.countdown = 60;
        this.startCountdown();
        this.getsendSms(this.dataList.transMobile);
      } else {
        this.alertMessage = "手机号无效或长度不是11位";
        alert('手机号无效或长度不是11位')
      }
    },

    //开始验证码倒计时
    startCountdown() {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown--;
          this.startCountdown();
        }, 1000);
      } else {
        this.isCounting = false;
        this.countdown = 60;
      }
    },

    //发送验证码
    getsendSms(phoneNum) {
      // console.log(phoneNum);
      getsendSms(phoneNum).then((res) => {
        this.dataList.uuid = res.uuid;
      });
    },

    //发送绑定信息表单 提交成功返回上层界面
    addWithdrawOrder(dataList) {
      addWithdrawOrder(dataList).then(
        (this.alertMessage = "绑定成功"),
        (this.showAlert = true),
        setTimeout(() => {
          this.showAlert = false;
          this.$router.go(-1);
        }, 3000)
      );
    },
  },
};
</script>

<template>
  <div class="bg">
    <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->

    <!-- 结算信息绑定-->
    <div class="tips">
      <div class="yellowblock"></div>
      <span class="title">结算信息绑定</span>
      <div class="tip-p">
        <form action="/submit-form" method="post">
          <!-- 姓名 -->
          <div>
            <label for="name">姓名</label>
            <input type="text" id="name" name="name" placeholder="请输入姓名" v-model="dataList.transRealName" required />
          </div>

          <!-- 身份证号 -->
          <div>
            <label for="idcard">身份证号</label>
            <input type="text" id="idcard" name="idcard" placeholder="请输入身份证号" v-model="dataList.transIdentityCard"
              required />
          </div>

          <!-- 联系电话 -->
          <div>
            <label for="phone">联系电话</label>
            <input type="tel" id="phone" name="phone" placeholder="请输入联系电话" v-model="dataList.transMobile" required />
          </div>

          <!-- 验证码 -->
          <div class="yzm">
            <label for="captcha">验证码</label>
            <input type="text" id="captcha" name="captcha" v-model="dataList.code" placeholder="请输入验证码" required />
            <button type="button" id="sendCaptcha" :disabled="isCounting" @click="getVerificationCode">
              {{ isCounting ? "重新发送(" + countdown + "s)" : "获取验证码" }}
            </button>
          </div>

          <div>
            <label for="bankcard">银行卡号</label>
            <input type="number" id="bankcard" name="bankcard" v-model="dataList.transSettleCard" placeholder="请输入银行卡号"
              required />
          </div>

          <div>
            <label for="agreeTerms" style="width: 61%; margin: 0 auto">
              <input type="checkbox" id="agreeTerms" name="agreeTerms" required />
              我已认真阅读并完全同意;《平台用户注册协议》、《共享经济合作伙伴协议》的所有条款
            </label>
          </div>
        </form>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="bottomChat">
      <div class="share-content">
        <div class="phoneBtn" @click="checkInput">去提现</div>
      </div>
    </div>

  </div>
</template>

<style lang="less" scoped>
@import "../../../config/less/global.less";

.bg {
  width: 100%;
  height: 100vh;
  background: yellow;
  background: @base-bgColor;
  overflow: hidden;
}

p {
  margin: 0;
}

.alert-box {
  position: absolute;
  top: 50%;
  left: 20%;
  width: 50%;
  padding: 10px;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.alert-box .title {
  position: relative;
  color: #ffffff;
}

.alert-box button {
  width: 30%;
  margin-top: 30px;
  margin: 20px auto 0px 60%;
  background-color: white;
  padding: 5px 0;
  border: none;
  color: black;
}

.tips {
  position: relative;
  width: 99%;
  padding: 40px 0px;
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
}

.tip-p {
  position: absolute;
  top: 60%;
  left: 5%;
  width: 100%;
  height: 10px;
}

.tip-p div {
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
}

input:not(#agreeTerms) {
  height: 40px;
  background-color: #f5f5f5;
  border: #aaa7a7 solid 2px;
  border-radius: 10px;
}

input:not(#agreeTerms, .yzm input) {
  width: 60%;
}

form label {
  display: block;
  width: 23%;
  text-align: justify;
  font-size: 18px;
  line-height: 40px;
}

.yzm input {
  width: 30%;
}

.yzm button {
  width: 27%;
  margin-left: 10px;
  padding: 0px 10px;
  background-color: #ffd866;
  border: none;
  border-radius: 10px;
}

.outputitem {
  position: absolute;
  top: 34%;
  left: 5%;
  width: 90%;
  height: 130px;
  // background-color: #ffd866;
}

.yellowblock {
  position: absolute;
  width: 2%;
  height: 35px;
  top: 10%;
  left: 2%;
  background-color: #ffd866;
}

.bottomChat {
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.share-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.phoneBtn {
  width: 100%;
  height: 35px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  background-color: #ffd866;
}

.title {
  position: absolute;
  top: 10%;
  left: 7%;
  display: flex;
  line-height: 40px;
  font-size: 18px;
}
</style>
