import request from '@/utils/request'

/**
 * 信息列表
 * @param query
 * @returns {*}
 */
// 信息列表
export function getAgentAccountLog(query) {
    return request({
        url: '/user/agentAccountLog/list',
        method: 'get',
        params: query
    })
}

/**
 * 合计
 * @param query
 * @returns {*}
 */
// 合计
export function getAccountLogSumList(query) {
    return request({
        url: '/user/agentAccountLog/sumList',
        method: 'get',
        params: query
    })
}

/**
 * 创建提现订单
 * @param query
 * @returns {*}
 */
// 创建提现订单
export function withdraworder(data) {
    return request({
        url: '/user/withdraworder/add',
        method: 'post',
        data: data
    })
}
