import Vue from "vue";
import VueRouter from "vue-router";
import Root from "../views/root/index.vue";
import Login from "../views/login/login.vue";
import Main from "../views/root/main.vue";
import NotFound from "../views/root/NotFound.vue";
import Module1 from "../views/module1/index.vue";
import Shenka from "../views/module1/Shenka.vue";
import Module2 from "../views/module2/index.vue";
import Module3 from "../views/module3/index.vue";
import GoodsDetail from "../views/module1/GoodsDetail";
import WithdrawalDetails from "../views/module3/withdrawalDetails/index.vue";
import Withdraw from "../views/module3/withdraw/index.vue";
import BindingCard from "../views/module3/bindingCard/index.vue";
import History from "../views/module3/history/index.vue";
import Team from "../views/module3/team/index.vue";
import Events from "../views/module3/events/index.vue";
import Monad from "../views/module3/monad/index.vue";
import Notification from "../views/module3/notification/index.vue";
import Recommended from "../views/module3/recommended/index.vue";
import Schedule from "../views/module3/schedule/index.vue";
import SignIn from "../views/login/signIn.vue"
import Inviter from "../views/module3/inviter/index.vue"
import PushCard from "../views/module1/pushcard.vue"

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Root", component: Root },
  { path: "/Login", name: "Login", component: Login },
  {
    path: '/Main',
    name: "Main",
    component: Main,
    children: [
      { path: "/Module1", name: "Module1", component: Module1 },
      { path: "/Module2", name: "Module2", component: Module2, meta: { isShowTabBar: true }, },
      { path: "/Module3", name: "Module3", component: Module3 },
    ],
  },
  { path: "/GoodsDetail", name: "GoodsDetail", component: GoodsDetail },
  { path: "/Shenka", name: "Shenka", component: Shenka },
  { path: "/WithdrawalDetails", name: "WithdrawalDetails", component: WithdrawalDetails },
  { path: "/Withdraw", name: "Withdraw", component: Withdraw },
  { path: "/BindingCard", name: "BindingCard", component: BindingCard },
  { path: "/History", name: "History", component: History },
  { path: "/Team", name: "Team", component: Team },
  { path: "/Events", name: "Events", component: Events },
  { path: "/Monad", name: "Monad", component: Monad },
  { path: "/Notification", name: "Notification", component: Notification },
  { path: "/Recommended", name: "Recommended", component: Recommended },
  { path: "/Schedule", name: "Schedule", component: Schedule },
  { path: "/SignIn", name: "SignIn", component: SignIn },
  { path: "/Inviter", name: "Inviter", component: Inviter },
  { path: "/PushCard", name: "PushCard", component: PushCard },
  { path: "/NotFound", name: "NotFound", component: NotFound },
  // 所有未定义路由，全部重定向到404页，必须放在最后
  {
    path: '*',
    redirect: '/404'
  }

];




const router = new VueRouter({
  routes,
  // mode: 'hash',
  // base: process.env.BASE_URL,
  // /**
  //  * 路由跳转后页面滚动到顶部
  //  * 详见：https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html
  //  */
  // scrollBehavior: () => ({ y: 0 }),
});




/*

只能永久，需要手动清除
存储：window.localStorage.setItem(name, JSON.stringify(obj))
读取：window.localStorage.getItem(name)
删除：window.localStorage.removeItem(name)
清空：window.localStorage.clear()
*/


// import {getToken} from "@/utils/auth";
// // 路由前置守卫
// router.beforeEach((to, from, next) => {
//   const { meta, name } = to;
//   const { isLogin } = meta;
//   let token = getToken();

//   // token不存在时跳转非登录页，重定向到登录页
//   if (!token && name !== 'Login' && !isLogin) {
//     next({ name: 'Login' });
//   } else if (token && name === 'Login') {
//     // next({ name: 'Main' });
//     next({ name: 'Module1' });
//   }
//   // 其他场景
//   else next();
// });


export default router;
