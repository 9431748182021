<template>
  <div class="bg">
    <!-- <BaseNavBar :title="title" :isBack="isBack"> </BaseNavBar> -->
    <div class="lunbo">
      <img :src="bannerList.bankLogo" />
      <!-- <img src="../../assets/img/yinhangka.png" alt="图" /> -->
      <div class="lunbo-right">
        <p class="lunbo-title">{{ bannerList.bankName }}</p>

        <div class="introduce">
          <p>{{ bannerList.label }}</p>
          <p>{{ bannerList.labelOne }}</p>
        </div>
      </div>
    </div>

    <!--      详细信息-->
    <div class="detail">
      <!-- 卡的具体信息 -->
      <div class="content">
        <div v-for="item in bannerList.ccPromptContentlist">
          <p>【 {{ item.title }} 】</p>
          <p>{{ item.text }}</p>
        </div>
      </div>

      <!-- 可推广卡片 -->
      <div class="desc">
        <img src="../../assets/img/tuiguang.png" alt="" />
      </div>

      <div class="card">
        <p class="card-title">{{ bannerList.bankName }}</p>
        <!-- <img class="yinhangka" src="../../assets/img/yinhangka.png" alt=""> -->
        <img class="yinhangka" :src="bannerList.bankLogo" alt="" />

        <div class="cardintroduce">
          <div>
            <img src="../../assets/img/yellow.png" /><span>{{ bannerList.label }}</span>
          </div>
          <div>
            <img src="../../assets/img/yellow.png" /><span>{{ bannerList.labelOne }}</span>
          </div>
        </div>

        <div class="cardbtn">
          <button class="btn1" @click="showModal = true">本人申请</button>
          <button class="btn2" @click="PushCard">推荐办卡</button>
        </div>
      </div>
    </div>

    <!-- 底部俩按钮 -->
    <div class="bottomChat">
      <div class="share-content">
        <div class="phoneBtn btn1" @click="showModal = true">立即申请</div>
        <div class="phoneBtn btn2" @click="PushCard">推荐办卡</div>
      </div>
    </div>

    <!-- 点击后的窗口 -->
    <div>
      <!-- 遮罩层 -->
      <div
        class="modal-overlay"
        v-show="showModal"
        @click="showModal = false"
      ></div>

      <!-- 模态窗口 -->
      <div class="modal" v-show="showModal">
        <h2>合伙人推广规范</h2>
        <!-- 内容部分 -->
        <div class="modal-content">
          <p v-for="(item, index) in norm" :key="index">{{ item }}</p>
          <!-- 底部按钮 -->
          <div class="modal-footer">
            <button @click="handleOk">本人申请</button>
            <button @click="handleCancel">客户申请</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getbankBrandList } from "@/api/bankBrand";
export default {
  name: "GoodsDetail",
  data() {
    return {
      id: "",
      title: "申卡",
      isBack: true,
      bannerList: [],
      detail: {},
      showModal: false,
      norm: [
        "1、合伙人应告知客户所办理业务为申办信用卡",
        "2、禁止任何性质的电话营销",
        "3、禁止冒充银行工作人员",
        "4、禁止代客户填写申请资料",
        "5、禁止虚假承诺任何礼品及授信额度",
        "6、禁止填写虚假信息",
      ],
      imgurl: null,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getbankBrandList(this.id);
  },
  mounted() {
    document.title = this.title;
  },
  methods: {
    // 本人申请
    handleOk() {
      this.$router.push({
        name: "Shenka",
        query: {
          id: this.id,
          status: 1,
        },
      });
      this.showModal = false;
    },

    // 客户申请
    handleCancel() {
      this.$router.push({
        name: "Shenka",
        query: {
          id: this.id,
          status: 0,
        },
      });
      this.showModal = false;
    },

    // 推荐办卡
    PushCard() {
      this.$router.push({
        name: "PushCard",
        query: {
          id: this.id,
          name: this.bannerList.bankName,
          url: this.imgurl,
        },
      });
      this.showModal = false;
    },

    //获取银行信息
    getbankBrandList(id) {
      getbankBrandList({ id }).then((res) => {
        this.bannerList = res.data;
        console.log(res.data);
        this.imgurl = res.data.posterUrl;
      });
    },
  },
};
</script>

<style scoped>
.bg {
  /* overflow: hidden; */
  /* height: 120vh; */
  overflow-x: hidden;
}
p,
div {
  margin: 0;
  padding: 0;
}

h2 {
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; /* 确保在模态窗口之下 */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal {
  position: absolute;
  top: 30%;
  left: 5%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  z-index: 1022;
  width: 80%;
  height: 300px;
  overflow: hidden;
}

.modal p {
  line-height: 150%;
  overflow: hidden;
}

.modal-footer {
  position: absolute;
  width: 100%;
  top: 80%;
  left: 3%;
}

.modal-footer button {
  margin: 0px 30px;
  padding: 10px 20px;
  width: 30%;
  border: none;
  border-radius: 20px;
  background-color: #f3b143;
  color: #ffffff;
  font-size: 14px;
}

.lunbo {
  font-weight: 600;
  position: relative;
  width: 100%;
  height: 130px;
  /* padding: 50px; */
  background-image: url(../../assets/img/background/bak2.png);
  /* 背景图垂直、水平均居中 */
  background-position: center center;
  /* 背景图不平铺 */
  background-repeat: no-repeat;
  /* 让背景图基于容器大小伸缩 */
  background-size: cover;

  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.lunbo-title {
  font-size: 20px;
}

.lunbo img {
  position: absolute;
  width: 30%;
  top: 20%;
  left: 4%;
}

.lunbo .lunbo-right {
  position: absolute;
  top: 20%;
  left: 40%;
  color: #ffffff;
}

.introduce {
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
}

.introduce p {
  line-height: 20px;
  font-size: 12px;
  width: 40px;
}

.detail {
  /* position: relative; */
  background-color: #f7f7f7;
  /* top: 0px; */
  /* height: 100%; */
  padding-bottom: 20vh;
}

.title {
  font-size: 22px;
  color: #363636;
  font-weight: bold;
}

.content {
  width: 95%;
  background-color: #ffffff;
  color: #969696;
  margin: 5px auto 5px auto;
  padding: 10px 0;
  /* height: 300px; */
}

.content {
  line-height: 25px;
}

.desc {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  color: #333;
}

.desc img {
  display: flex;
  justify-content: center;
  width: 40%;
  margin: 0 auto;
  margin-top: 30px;
}

.card {
  position: relative;
  width: 90%;
  height: 180px;
  background-color: #ffffff;
  margin: 10px auto;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
}

.card .yinhangka {
  position: absolute;
  width: 40%;
  top: 35%;
  left: 6%;
}

.card-title {
  position: absolute;
  top: 10%;
  left: 7%;

  font-size: 23px;
  font-weight: 600;
  color: #666666;
}

.cardintroduce {
  position: absolute;
  top: 37%;
  left: 50%;
  /* width: 100px; */
  color: #666666;
  display: flex;
  flex-direction: column;
}

.cardintroduce div {
  display: flex;
  flex-direction: row;

  margin: 3px 0;
}

.cardintroduce span {
  display: block;
  height: 30px;
  color: #666666;
  font-size: 14px;
}

.cardintroduce img {
  width: 20%;
  height: 10%;
  /* margin-top: 1%; */
}

.cardbtn {
  position: absolute;
  width: 100%;
  top: 71%;
  left: 50%;
}

.cardbtn .btn1 {
  border: solid #fa566f 2px;
  border-radius: 20px;
  color: #fa566f;
}

.cardbtn .btn2 {
  border: solid #ff7518 2px;
  border-radius: 20px;
  color: #ff7518;
  margin-left: 10px;
}

.cardbtn button {
  background-color: #ffffff;
  padding: 5px 7px;
  width: 23%;
}

.bottomChat {
  background-color: #ffffff;
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.share-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.share {
  display: flex;
  margin-top: 10px;
}

.share img {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  margin-right: 5px;
}

.phoneBtn {
  margin: 4.5% 4%;
  width: 160px;
  height: 35px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #ffffff;
}

.share-content .btn1 {
  background-color: #fa6176;
}

.share-content .btn2 {
  background-color: #f6ce68;
}
</style>
